import { buildEmberRouteRedirect, getEventLogger, QueryParamsProps } from '../..';
import { prerequisiteRedirectAllowlist, AllowedRoutesAndUrls } from './prerequisiteRedirectAllowlist';

export type PrerequisiteRedirect = {
  id: string;
  status?: string; // OPN or CMP?
  group: string;
  data?: {
    destination: string;
    type: string;
    routeModelId?: number | string;
    params?: any;
  };
  objectType?: string;
};

export const DESTINATION_TYPE = {
  ROUTE_NAME: 'ember_route_name',
  URL: 'url',
};

export function redirectToUrl(url: string) {
  // Only allow 'http://*.zenefits.com' or 'https://*.zenefits.com' domains. Use only for redirects to
  // non-Ember, non-Client-App URLs.
  try {
    const parsedUrl = new URL(url);
    const isValidUrl = parsedUrl.hostname.endsWith('zenefits.com') || parsedUrl.hostname.endsWith('dev.zncloud.net');
    if (!isValidUrl) {
      getEventLogger().logError(new Error(`prerequisite_redirect - invalid url: ${url}`));
      return null;
    }
    return url;
  } catch (err) {
    getEventLogger().logError(new Error(`prerequisite_redirect - invalid url: ${url}`));
    return null;
  }
}

export function redirectToRoute(redirect: PrerequisiteRedirect) {
  const { group, data } = redirect;
  const whiteList = group && prerequisiteRedirectAllowlist[group];
  const destination = whiteList && whiteList.destination;

  const params: QueryParamsProps = { to: destination };
  if (typeof data?.routeModelId !== 'undefined') {
    params.routeParams = [data.routeModelId];
  }
  if (typeof data?.params !== 'undefined') {
    params.queryParams = data.params;
  }

  return buildEmberRouteRedirect(params);
}

/**
 * Redirect to the prerequisite redirect url if destination url
 * is not whitelisted ember route or whitelisted url.
 *
 * User will be navigated to the dashboard with the blocking modal if redirect group is in paywallRedirectGroupAllowList.
 * User will be navigated to the specific URL or Route defined in the first redirect
 *
 * @param redirects: prerequisite redirect object passed from backend.
 *   Users have to resolve these one by one, therefore we are only concerned with the first one.
 *   https://github.com/zenefits/z-frontend/pull/7155#discussion_r378638261
 *
 * @param pathBeforeUrlHash: url path before #, ex: http://localhost:3043/dashboard/#/ -> /dashboard/
 * @param pathAfterUrlHash: url path after #,
 *   ex: http://localhost:3043/dashboard/#/redirect-to-route?to=companyProfile.billingInformation ->
 *   /redirect-to-route
 *
 * @param searchQueryParams: search query params,
 *   ex: http://localhost:3043/dashboard/#/redirect-to-route?to=companyProfile.billingInformation ->
 *   ?to=companyProfile.billingInformation
 */
export function getRedirectUrl(
  redirects: PrerequisiteRedirect[],
  pathBeforeUrlHash: string,
  pathAfterUrlHash: string,
  searchQueryParams?: string,
): string | null {
  if (redirects.length === 0) {
    return null;
  }

  const [redirect] = redirects;
  const whitelist = redirect && prerequisiteRedirectAllowlist[redirect.group];
  if (!whitelist) return null;
  const completeUrl = `${pathBeforeUrlHash}#${pathAfterUrlHash}${searchQueryParams || ''}`;

  const isCurrentUrlAllowed = whitelist.allowedRoutesAndUrls.some((allowedRouteAndUrl: AllowedRoutesAndUrls) => {
    return (
      completeUrl.includes(allowedRouteAndUrl.path) ||
      (searchQueryParams && allowedRouteAndUrl.emberRoute && searchQueryParams.includes(allowedRouteAndUrl.emberRoute))
    );
  });
  if (isCurrentUrlAllowed) return null;

  // redirect to dashboard with paywall
  if (paywallRedirectGroupAllowList.some(group => group === redirect.group)) {
    const alreadyOnDashboard = completeUrl === '/dashboard/#/' || completeUrl === '/app/boot/#/';
    if (alreadyOnDashboard) {
      return null;
    } else {
      return `/dashboard/#/`;
    }
  }

  // else redirect to url defined on redirect
  const data = redirect.data || ({} as PrerequisiteRedirect['data']);

  if (data?.type === DESTINATION_TYPE.URL && completeUrl !== whitelist.destination) {
    return redirectToUrl(data?.destination);
  } else if (data?.type === DESTINATION_TYPE.ROUTE_NAME && !searchQueryParams?.includes(whitelist.destination)) {
    return redirectToRoute(redirect);
  }

  return null;
}

/**
 * A list of redirect groups that
 * should NOT trigger redirection in AppRedirects component,
 * should show a redirect model in Dashboard component,
 * should trigger redirect in function redirectIfNecessary.
 */
export const paywallRedirectGroupAllowList: string[] = [
  // Please sort alphabetically
  'bundle-expired',
  'delinquent-customer-billing-admins',
  'delinquent-customer-non-billing-admins',
  'test-group',
];
