// @ts-ignore
import { cookie } from 'z-frontend-zen-js';

import { colorsMap, ColorString } from './colors';
import icons from './icons';
import depths from './depths';
import boxShadows from './boxShadows';
import { default as themeImages } from './themeImages';

export const opacities = [0.65];

const isInEmbeddedNativeView = window.ZENEFITS_MOBILE_INTEGRATION?.isEmbeddedNativeView;
const themeName: string = cookie.get('Z_THEME_NAME') || 'zenefits';
const images = themeImages[themeName];

const themeObject = {
  opacities,
  icons,
  images,
  boxShadows,
  depths,
  radius: 2,
  colors: colorsMap,
  space: [0, 4, 8, 16, 24, 32, 64, 128],
  buttonSpace: [0, 4, 6, 8, 10, 12, 14, 16],
  heights: {
    xsmall: 24,
    small: 32,
    medium: 40,
    large: 48,
    xlarge: 64,
    xxlarge: 80,
    xxxlarge: 152,
  },
  borderColor: 'grayscale.f' as ColorString,
  topNavHeightInPx: isInEmbeddedNativeView ? 0 : 65,
  // Height of the topNav including any banner. This is what to use if you want to position against the height of the TopNav.
  topNavHeightContainer: isInEmbeddedNativeView ? '0px' : '65px',
  productNavHeight: '48px',
  productNavHeightInPx: 48,
};

export interface ThemeInterfaceCommon {
  colors: typeof colorsMap;
  opacities: typeof themeObject.opacities;
  icons: typeof themeObject.icons;
  images: typeof themeObject.images;
  radius: typeof themeObject.radius;
  space: typeof themeObject.space;
  buttonSpace: typeof themeObject.buttonSpace;
  heights: typeof themeObject.heights;
  boxShadows: typeof themeObject.boxShadows;
  depths: typeof themeObject.depths;
  borderColor: ColorString;
  topNavHeightContainer: string;
  topNavHeightInPx: number;
  productNavHeight: string;
  productNavHeightInPx: number;
  fontSizes?: number[];
  fontStyles?: { [key: string]: string };
  fonts?: string[];
  font?: string;
  weights?: number[];
  breakpoints?: number[];
  zIndex?: { [key: string]: number };
  iconFont?: string;
}

export const themeCommon: ThemeInterfaceCommon = themeObject;
