import { ZAppInstallations, ZAppInstallStatus } from '../types';

const idPrefixes = [
  { domain: 'console.zenefits.com', prefix: '' },
  { domain: 'secure.zenefits.com', prefix: '' },
  { domain: 'alpha.zenefits.com', prefix: 'alpha:' },
  { domain: /.*\.dev\.zncloud\.net/, prefix: 'spoof:' },
];

// To test webhooks make yourpeopledemo a normal account. If it's excluded from analytics webhooks also skip it
const allowListedDemoVistorIds = ['a354ef55684443804d594081427e43117fcc154c679e0f25ad08a2a8c1456116'];

// Exported for unit testing, should not be used directly. Use `generatePendoIds`
export function _getId(baseId: string, host: string, options: { denylistCompany: boolean; skipDemoPrefix?: boolean }) {
  const foundPrefix = idPrefixes.find(idPrefix => host.match(idPrefix.domain));

  const envPrefix = foundPrefix ? foundPrefix.prefix : 'dev:';
  const demoPrefix = options.denylistCompany && !options.skipDemoPrefix ? 'demo:' : '';
  return `${envPrefix}${demoPrefix}${baseId}`;
}

export function generatePendoIds(userIntegrationHash: string, companyId: string, denylistCompany: boolean) {
  if (window.Cypress) {
    return { visitorId: 'dev:cy_user', accountId: 'dev:cy_account' };
  }
  if (!userIntegrationHash) {
    // for anonymous users we don't have IDs
    return {};
  }
  return {
    accountId: _getId(companyId, window.location.hostname, {
      denylistCompany,
      skipDemoPrefix: allowListedDemoVistorIds.includes(userIntegrationHash),
    }),
    visitorId: _getId(userIntegrationHash, window.location.hostname, {
      denylistCompany,
      skipDemoPrefix: allowListedDemoVistorIds.includes(userIntegrationHash),
    }),
  };
}

export async function assignPendoIds(cb: (pendoIds: { visitorId: string; accountId: string }) => void) {
  const pendoIds = getPendoIdsIfInitialized();
  if (pendoIds) {
    cb(pendoIds);
  } else {
    window.document.addEventListener('pendo_ready', () => {
      // If statement is here to make typescript happy
      if (window.pendo) {
        cb({
          visitorId: window.pendo?.getVisitorId(),
          accountId: window.pendo?.getAccountId(),
        });
      }
    });
  }
}

export function getPendoIdsIfInitialized() {
  if (window.pendo?.isReady && window.pendo.isReady()) {
    return {
      visitorId: window.pendo.getVisitorId(),
      accountId: window.pendo.getAccountId(),
    };
  }

  return null;
}

// Check in both the url qs and a qs on the end of the hash
export function parseFieldsFromQS(location: Location) {
  const qs = new URL(location.href).searchParams;
  // Parse the hash as if it's the url pathname to take advantage of built in hash parsing
  const hashQs = new URL(location.hash.slice(1), location.href).searchParams;
  hashQs.forEach((val, key) => {
    qs.set(key, val);
  });

  return qs;
}

const statusStringToCode = {
  deleted: ZAppInstallStatus.deleted,
  disabled: ZAppInstallStatus.disabled,
  initializing: ZAppInstallStatus.initializing,
  notEnrolled: ZAppInstallStatus.notEnrolled,
  enrolling: ZAppInstallStatus.enrolling,
  ok: ZAppInstallStatus.ok,
};

export function has401k(zappInstallations: ZAppInstallations | undefined) {
  const four01kStatusString = zappInstallations?.['F01kAdmin'];
  const four01kStatusCode =
    four01kStatusString && statusStringToCode[four01kStatusString as keyof typeof statusStringToCode];
  return !!four01kStatusCode && [ZAppInstallStatus.enrolling, ZAppInstallStatus.ok].includes(four01kStatusCode);
}
