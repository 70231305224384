import React, { useContext, FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { throwInDevelopment } from 'z-frontend-app-bootstrap';

import { useQueryWithProgress } from '../../index';
import { AnonymousUserContext } from '../AnonymousUserProvider';

declare global {
  interface Window {
    __zenefitsDev: { switches?: Switches };
  }
}

// TODO: change this to read from the rootQuery
export const dashboardSwitchQuery = gql`
  query DashboardSwitchQuery {
    dashboard {
      id
      switches
    }
  }
`;

// TODO: unify this with the query from app-bootstrap to avoid 2 queries for the same thing
export const anonymousUserSwitchQuery = gql`
  query AnonymousUserSwitchQuery {
    switches
  }
`;

export type Switches = { [switchName: string]: boolean };

export type SwitchContextProps = {
  switches: Switches;
  switchesLoaded: boolean;
  refetchSwitches: () => any;
};

const nullSwitchContext = {
  switches: {},
  switchesLoaded: true,
  refetchSwitches: () => {
    throwInDevelopment('You tried to fetch switches outside the context of a switch manager');
  },
};

export const SwitchContext = React.createContext<SwitchContextProps>(nullSwitchContext);

const useFetchSwitches = (isAnonymousUser: boolean) => {
  const query = isAnonymousUser ? anonymousUserSwitchQuery : dashboardSwitchQuery;
  const { data, loading, refetch } = useQueryWithProgress(query, {
    context: { headers: { 'IS-BACKGROUND-QUERY': true } },
  });

  if (!data || loading) {
    return { data, loading, refetch };
  }

  const parsedData = isAnonymousUser ? data?.switches : data?.dashboard?.switches;
  return { refetch, loading, data: parsedData };
};

const SwitchProvider: FunctionComponent = props => {
  const { isAnonymousUser } = useContext(AnonymousUserContext);
  const { data: switches, loading, refetch } = useFetchSwitches(isAnonymousUser);

  if (__DEVELOPMENT__) {
    window.__zenefitsDev = window.__zenefitsDev || {};
    window.__zenefitsDev.switches = switches;
  }
  window.zen = window.zen || {};
  window.zen.switches = switches;

  return (
    <SwitchContext.Provider
      value={{
        switches,
        switchesLoaded: !loading,
        refetchSwitches: refetch,
      }}
    >
      {props.children}
    </SwitchContext.Provider>
  );
};

export default SwitchProvider;
