import React, { Component } from 'react';
import { getIn, FieldProps } from 'formik';

import Field from '../Field';
import FormFieldWrapper, { FormFieldProps } from '../FormFieldWrapper';
import NumberInput, { NumberInputProps } from '../../number-input/NumberInput';
import { getAriaInputProps } from '../formAccessibility';
import { InputDisplay } from '../../..';

export type FormNumberInputProps = NumberInputProps &
  FormFieldProps & {
    /**
     * Use to toggle the component from edit to display but keep exact spacing, eg in an EditableTable.
     * @default false
     */
    displayOnly?: boolean;
  };

class FormNumberInput extends Component<FormNumberInputProps> {
  render() {
    const {
      name,
      label,
      containerProps,
      optional,
      limitRerender,
      dependencies,
      format,
      'aria-label': ariaLabel,
      helpText,
      displayOnly,
      prefix,
      suffix,
      disableError,
      ...rest
    } = this.props;
    return (
      <Field name={name} limitRerender={limitRerender} dependencies={dependencies}>
        {({ field, form }: FieldProps) => {
          const error: any = getIn(form.touched, name) && getIn(form.errors, name);
          return (
            <FormFieldWrapper
              name={name}
              label={label}
              helpText={helpText}
              error={error}
              containerProps={containerProps}
              optional={optional}
              format={format}
              isDisplayMode={displayOnly}
              disableError={disableError}
            >
              {displayOnly ? (
                <InputDisplay
                  prefix={prefix}
                  suffix={suffix}
                  value={formatNumberForDisplay(this.props, field.value || rest.value)}
                />
              ) : (
                <NumberInput
                  id={name}
                  {...field}
                  onChange={(value: number | string) => {
                    form.setFieldValue(name, value);
                  }}
                  {...rest}
                  hasError={Boolean(error)}
                  {...getAriaInputProps(name, error, ariaLabel)}
                  mb={0}
                  prefix={prefix}
                  suffix={suffix}
                />
              )}
            </FormFieldWrapper>
          );
        }}
      </Field>
    );
  }
}

export default FormNumberInput;

/**
 * Formats the number value for 'displayOnly' mode based on the component properties. Currently
 * this only entails adding the thousands separators.
 */
export function formatNumberForDisplay(props: FormNumberInputProps, value: number | string) {
  // To do the formatting, we are avoiding re-using the logic that is present in <NumberFormat> because
  // it uses 'conformToMask' which silently removes everything that does not conform to mask. We would
  // want to avoid that at least in 'displayOnly' mode - best to display exactly what we have in our storage
  // backend instead of silently trowing away stuff. It could confuse the user.
  //
  // Best would be to also fix it for edit mode, but we didn't want to go into it at this point. It's okay
  // to enforce the mask if the user is actively editing the field, but if the initialValue was non-conformant,
  // best is to display it as is to avoid writing back an updated value to to backend without the user realizing
  // that he touched the field.
  //
  // TODO: https://jira.inside-zen.com/browse/ZPP-2742 - to address the above.

  // The only point of this function is to add the thousands separators - if not required, don't mess with the value.
  if (!(props.includeThousandsSeparator ?? true)) {
    return value;
  }

  // Falsy values will not need a thousands separator.
  if (!value) {
    return value;
  }

  const number = Number(value);

  // If the value is not a valid number - don't touch it, we want to display exactly what is in backend.
  if (isNaN(number)) {
    return value;
  }

  // The below call adds a comma as thousands separator. Do not truncate fractional digits.
  const enoughDigits = 15;
  return number.toLocaleString('en-US', { maximumFractionDigits: enoughDigits });
}
