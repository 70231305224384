import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, ChildProps, MutationFunction } from 'react-apollo';
import { flowRight } from 'lodash';
import Yup from 'yup';

import { Box, Flex, TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import {
  FileResponse,
  Form,
  FormikHelpers,
  FormCheckboxGroup,
  FormDateInput,
  FormFileUploader,
  FormMoneyInput,
  FormMultiSelect,
  FormNumberInput,
  FormRadio,
  FormRadioGroup,
  FormTextarea,
} from 'z-frontend-forms';
import { withGraphqlProgress, SwitchContext } from 'z-frontend-network';
import { generateUUID, getEventLogger } from 'z-frontend-app-bootstrap';

import { CreateImplementationEmail, CreateSupportCase, JSON, SupportRequestQuery } from '../gqlTypes';
import {
  IMPLEMENTATION_CONTACT_OPTIONS,
  METHOD_CHAT,
  METHOD_QUICKSTART_CHAT,
  METHOD_QUICKSTART_EMAIL,
} from './constants';
import { SupportFlowPages, SupportFlowPageProps } from '../support-flow/types';

type FieldMetadata = {
  options: JSON;
  style: JSON;
  [key: string]: string;
};

type FieldData = {
  text?: string;
  helpText?: string;
  metadata?: FieldMetadata;
  dataType?: string;
};

type FormData = {
  topic: string;
  [key: string]: any;
};

type EmployeeData = { id?: string; name?: string };

type MultiSelectData = { id: string; text: string };

type State = {
  errorOnSend?: boolean;
  errorOnChat?: boolean;
  errorMessage?: string;
};
type OnSubmitProps = {
  values: FormData;
  actions: FormikHelpers<FormData>;
  isOutsideBusinessHours: boolean;
  path: string;
  canEdit?: boolean;
  method: string;
  topicId: string;
  disableSupportChat: boolean;
  isOutsideImplementationHours: boolean;
  disableImplementationChat: boolean;
};
type ProcessImplementationContactProps = {
  values: FormData;
  actions: FormikHelpers<FormData>;
  isOutsideImplementationHours: boolean;
  topicId: string;
  path: string;
  method: string;
  disableChat: boolean;
};
export type Props = SupportFlowPageProps & {
  goToChat: (caseId: string, coordinatorType: string) => void;
  method: string;
  topicId: string;
  isMobileView?: boolean;
};
type MutationProps = {
  createSupportCase: MutationFunction<CreateSupportCase.Mutation, CreateSupportCase.Variables>;
  createImplementationEmailRequest: MutationFunction<
    CreateImplementationEmail.Mutation,
    CreateImplementationEmail.Variables
  >;
};
type AllProps = ChildProps<Props, SupportRequestQuery.Query, SupportRequestQuery.Variables> & MutationProps;

const CHAT_NOT_AVAILABILITY_MESSAGE =
  'Chat Support is currently not available.Please try again later or contact us by email.';
class SupportRequest extends Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      errorOnSend: false,
      errorOnChat: false,
    };
  }

  generateUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, current => {
      const random = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
      const value = current === 'x' ? random : (random & 0x3) | 0x8; // eslint-disable-line no-bitwise
      return value.toString(16);
    });
  };

  createDescription = (values: FormData) => {
    // Create description based of the values from form data.
    const description = Object.entries(values).map(([key, value]) => {
      let line;
      if (key === 'impacted') {
        const employees = value
          .map((employee: { name: string }) => {
            return employee.name;
          })
          .join(',');
        line = `IMPACTED EMPLOYEES : ${employees}`;
      } else if (key === 'topic') {
        // Skip topic because it's already there in the subject.
        // Check mutation.
        line = '';
      } else if (key === 'description') {
        line = value;
      } else {
        line = `${key.toUpperCase()} : ${value}`;
        if (typeof value[0] === 'object') {
          const lines = value.map((label: { text: string }) => {
            return label['text'];
          });
          line = `${key.toUpperCase()} : ${lines.join(', ')}`;
        }
      }
      return line;
    });
    return description.join('\n');
  };
  getCaseData = (values: FormData, topicId: string, contactMethod: string) => {
    return {
      description: this.createDescription(values),
      subject: values.topic,
      topicId: topicId === '0' ? null : topicId,
      attachments: (values.uploader || []).map((file: FileResponse) => {
        return { filename: file.fileName, url: file.fullFileUrl };
      }),
      uniqueId: generateUUID(),
      requestedContactMethod: contactMethod,
      suppliedPhone: '',
      coordinatorType: this.props.data.finalPage.coordinatorType,
      userAgent: navigator.userAgent,
    };
  };
  processImplementationContact = async (data: ProcessImplementationContactProps) => {
    const { values, actions, isOutsideImplementationHours, topicId, path, method, disableChat } = data;
    getEventLogger().log('implementation_support_flow_navigation', { path, method });
    const isQsChat = method === METHOD_QUICKSTART_CHAT;
    const isQsEmail = method === METHOD_QUICKSTART_EMAIL;
    const caseData = this.getCaseData(values, topicId, method);
    if (isQsEmail) {
      const result = await this.props.createImplementationEmailRequest({
        variables: {
          caseData,
        },
      });
      actions.setSubmitting(false);
      if (result && result.data && result.data.createImplementationEmail.success) {
        this.props.navigateTo(SupportFlowPages.requestReceived);
      } else {
        this.setState({ errorOnSend: true });
      }
      return;
    }
    if (isQsChat && (isOutsideImplementationHours || disableChat)) {
      if (isOutsideImplementationHours) {
        this.setState({ errorOnChat: true });
      } else {
        this.setState({ errorOnChat: true, errorMessage: CHAT_NOT_AVAILABILITY_MESSAGE });
      }
      actions.setSubmitting(false);
      return;
    }
    const result = await this.props.createSupportCase({
      variables: {
        caseData,
      },
    });
    if (result && result.data && result.data.createSupportCase) {
      if (isQsChat) {
        this.props.goToChat(result.data.createSupportCase.caseId, this.props.data.finalPage.coordinatorType);
      }
    } else {
      this.setState({ errorOnSend: true });
    }
    actions.setSubmitting(false);
  };

  onSubmit = async (data: OnSubmitProps) => {
    const {
      values,
      actions,
      isOutsideBusinessHours,
      path,
      method,
      topicId,
      disableSupportChat,
      isOutsideImplementationHours,
      disableImplementationChat,
    } = data;

    if (IMPLEMENTATION_CONTACT_OPTIONS.includes(method)) {
      const implementationData = {
        values,
        actions,
        isOutsideImplementationHours,
        topicId,
        path,
        method,
        disableChat: disableImplementationChat,
      };
      this.processImplementationContact(implementationData);
      return;
    }
    getEventLogger().log('support_flow_navigation', { path, method });
    if (method === METHOD_CHAT && (isOutsideBusinessHours || disableSupportChat)) {
      if (isOutsideBusinessHours) {
        this.setState({ errorOnChat: true });
      } else {
        this.setState({ errorOnChat: true, errorMessage: CHAT_NOT_AVAILABILITY_MESSAGE });
      }
      actions.setSubmitting(false);
      return;
    }
    const caseData = this.getCaseData(values, topicId, method);
    const result = await this.props.createSupportCase({
      variables: {
        caseData,
      },
    });
    if (result && result.data && result.data.createSupportCase) {
      this.setState({ errorOnSend: false });
      if (method === METHOD_CHAT) {
        this.props.goToChat(result.data.createSupportCase.caseId, this.props.data.finalPage.coordinatorType);
      } else {
        this.props.navigateTo(SupportFlowPages.requestReceived);
      }
    } else {
      this.setState({ errorOnSend: true });
    }
    actions.setSubmitting(false);
  };

  goBack = (path: string, canEdit: boolean) => {
    // log coveo cancel event
    getEventLogger().log('support_flow_navigation', { path, method: 'back_from_final' });
    this.props.goBack();
  };

  customFormComponent(field: FieldData) {
    // Function that returns React Form Components.
    // String, Money, Date, Checkboxes, Radio Buttons supported.
    if (field.dataType.toUpperCase() === 'NUMBER') {
      return (
        <FormNumberInput
          name={field.text}
          label={field.text}
          placeholder={field.helpText}
          key={field.text}
          allowDecimal
          allowNegative={false}
        />
      );
    } else if (field.dataType.toUpperCase() === 'STRING') {
      const rows = (field.metadata && field.metadata.style && field.metadata.style['rows']) || 5;
      return (
        <FormTextarea name={field.text} label={field.text} placeholder={field.helpText} key={field.text} rows={rows} />
      );
    } else if (field.dataType.toUpperCase() === 'DATE') {
      return (
        <FormDateInput
          name={field.text}
          label={field.text}
          key={field.text}
          placeholder={field.helpText.toUpperCase()}
        />
      );
    } else if (field.dataType.toUpperCase() === 'MONEY') {
      return (
        <FormMoneyInput label={field.text} name={field.text} key={field.text} allowDecimal allowNegative={false} />
      );
    } else if (field.dataType.toUpperCase() === 'CHECKBOX') {
      return (
        <FormCheckboxGroup key={field.text} name={field.text} label={field.text} helpText={field.helpText}>
          {({ Checkbox }) => (
            <>
              {field.metadata &&
                Object.keys(field.metadata.options)
                  .sort()
                  .map(key => <Checkbox key={key} name={key} label={key} />)}
            </>
          )}
        </FormCheckboxGroup>
      );
    } else if (field.dataType.toUpperCase() === 'RADIOBUTTON') {
      return (
        <FormRadioGroup key={field.text} name={field.text} label={field.text} helpText={field.helpText}>
          {field.metadata &&
            Object.keys(field.metadata.options)
              .sort()
              .map(key => <FormRadio key={key} value={key} label={key} />)}
        </FormRadioGroup>
      );
    } else if (field.dataType.toUpperCase() === 'MULTISELECT') {
      return (
        <FormMultiSelect<MultiSelectData>
          name={field.text}
          label={field.text}
          placeholder={field.helpText}
          getOptionText={option => option.text}
        >
          {({ SelectOption, multiOptionFilter }) =>
            multiOptionFilter(field.metadata.options).map(option => <SelectOption key={option.id} option={option} />)
          }
        </FormMultiSelect>
      );
    }
  }

  validationSchema(customFields: FieldData[]) {
    // Basic validation schema for the form.
    // Description is mandatory.
    if (customFields.length === 0) {
      return { description: Form.Yup.string().required('Description is required') };
    }
    const REQUIRED_LABEL = 'This is a required field';
    const schema = {} as { [key: string]: Yup.MixedSchema };
    customFields.map(field => {
      if (field.dataType.toUpperCase() === 'NUMBER') {
        schema[field.text] = Form.Yup.number()
          .required(REQUIRED_LABEL)
          .typeError(REQUIRED_LABEL);
      } else if (field.dataType.toUpperCase() === 'STRING') {
        schema[field.text] = Form.Yup.string().required(REQUIRED_LABEL);
      } else if (field.dataType.toUpperCase() === 'DATE') {
        schema[field.text] = Form.Yup.date()
          .required(REQUIRED_LABEL)
          .nullable();
      } else if (field.dataType.toUpperCase() === 'MONEY') {
        schema[field.text] = Form.Yup.number()
          .required(REQUIRED_LABEL)
          .nullable();
      } else if (field.dataType.toUpperCase() === 'CHECKBOX') {
        schema[field.text] = Form.Yup.array().required(REQUIRED_LABEL);
      } else if (field.dataType.toUpperCase() === 'MULTISELECT') {
        schema[field.text] = Form.Yup.array().required(REQUIRED_LABEL);
      } else if (field.dataType.toUpperCase() === 'RADIOBUTTON') {
        schema[field.text] = Form.Yup.string().required(REQUIRED_LABEL);
      }
    });
    return schema;
  }

  initialValues(canEdit: boolean, customFields: FieldData[], topic: string) {
    if (!canEdit) {
      return { topic: 'Read-Only Access' };
    }
    // Initial values when there are no custom fields.
    if (customFields.length === 0) {
      return { topic, description: '' };
    }
    const values: FormData = { topic: '' };
    values.topic = topic;
    customFields.map(field => {
      if (field.dataType.toUpperCase() === 'CHECKBOX' || field.dataType.toUpperCase() === 'MULTISELECT') {
        values[field.text] = [];
      } else if (field.dataType.toUpperCase() === 'RADIOBUTTON') {
        values[field.text] = field.metadata[Object.keys(field.metadata)[0]];
      } else {
        values[field.text] = '';
      }
    });
    return values;
  }

  render() {
    const { method, topicId, isMobileView } = this.props;
    const {
      finalPage,
      roleAndCategory,
      businessHoursSupport,
      readOnlyAccess,
      banner,
      businessHours,
      dashboard: { supportAvailability },
    } = this.props.data;
    const { isAdmin } = roleAndCategory;
    const { customSupportFields } = finalPage;
    const shouldDisableChat = !supportAvailability.chat;

    const isOutsideImplementationHours = !businessHours.isBusinessHours;
    const isOutsideSupportHours = !businessHoursSupport.isBusinessHours;
    const { canEdit } = readOnlyAccess;
    const hasCustomFields = customSupportFields.length > 0;
    const optionList = finalPage.employees;
    const isChat = method === METHOD_CHAT;
    const isQsChat = method === METHOD_QUICKSTART_CHAT;
    const isQsEmail = method === METHOD_QUICKSTART_EMAIL;

    const emergencyDisabled = banner.type === 'emergency';
    const disableSupportChat = isChat && (emergencyDisabled || shouldDisableChat);
    return (
      <SwitchContext.Consumer>
        {switches => {
          const disableImplementationChat = isQsChat && switches.switches.disable_implementation_chat;
          return (
            <Flex column w={isMobileView ? 1 : [1, 3 / 4, 1]} m="0 auto">
              <Box>
                <Form
                  onSubmit={(values, actions) => {
                    const data = {
                      values,
                      actions,
                      method,
                      topicId,
                      disableSupportChat,
                      isOutsideImplementationHours,
                      disableImplementationChat,
                      path: finalPage.path,
                      isOutsideBusinessHours: isOutsideSupportHours,
                    };
                    this.onSubmit(data);
                  }}
                  initialValues={this.initialValues(canEdit, customSupportFields, finalPage.path)}
                  validationSchema={this.validationSchema(customSupportFields)}
                >
                  <Card bg="grayscale.white">
                    {canEdit && (
                      <Card.Header>
                        <TextBlock color="text.default" fontStyle="headings.s">
                          {method === METHOD_CHAT ? 'Chat with an expert' : 'Get in touch with an expert'}
                        </TextBlock>
                      </Card.Header>
                    )}
                    <Card.Row>
                      <FormTextarea disabled label="What is this about?" name="topic" rows={2} />
                      {hasCustomFields &&
                        customSupportFields.map(field => {
                          return this.customFormComponent(field);
                        })}
                      {!hasCustomFields && (
                        <FormTextarea
                          name="description"
                          label="How can we help?"
                          placeholder="The more details you provide, the faster we can help."
                          rows={5}
                          height="auto"
                        />
                      )}
                      {!hasCustomFields && isAdmin && (
                        <FormMultiSelect<EmployeeData>
                          name="impacted"
                          label="Employees impacted, if any"
                          placeholder="Select Employees"
                          getOptionText={o => o.name}
                        >
                          {({ SelectOption, multiOptionFilter }) =>
                            multiOptionFilter(optionList).map(option => (
                              <SelectOption key={option.id} option={option} />
                            ))
                          }
                        </FormMultiSelect>
                      )}
                      {!isQsEmail && (
                        <FormFileUploader
                          name="uploader"
                          label="Related files or screenshots"
                          acceptedFileTypes="all"
                          category="support_flow_images"
                        />
                      )}
                      {this.state.errorOnSend && (
                        <Form.Error
                          mt={5}
                          textDefault="We were unable to send your form. Please try again or try later."
                        />
                      )}
                      {this.state.errorOnChat && (
                        <Form.Error
                          mt={5}
                          textDefault={
                            !this.state.errorMessage
                              ? 'We are currently outside our designated business hours. Please try again later or contact us by email.'
                              : this.state.errorMessage
                          }
                        />
                      )}
                    </Card.Row>
                    <Card.Footer>
                      <Form.Footer
                        primaryText={this.props.method === METHOD_CHAT ? 'Start Live Chat' : 'Submit'}
                        cancelShown={!!this.props.goBack}
                        cancelText="Back"
                        cancelOnClick={() => this.goBack(finalPage.path, canEdit)}
                      />
                    </Card.Footer>
                  </Card>
                </Form>
              </Box>
            </Flex>
          );
        }}
      </SwitchContext.Consumer>
    );
  }
}

export const supportRequestQuery = gql`
  query supportRequestQuery($topicId: ID) {
    dashboard {
      id
      supportAvailability {
        chat
        phone
      }
    }
    finalPage(topicId: $topicId) {
      coordinatorType
      path
      employees {
        name
        id
      }
      customSupportFields {
        text
        helpText
        metadata
        dataType
      }
    }
    roleAndCategory {
      isAdmin
      category
    }
    businessHoursSupport {
      isBusinessHours
    }
    businessHours {
      isBusinessHours
    }
    readOnlyAccess {
      canEdit
    }
    banner {
      show
      type
    }
  }
`;

const createSupportCase = gql`
  mutation createSupportCase($caseData: JSON) {
    createSupportCase(caseData: $caseData) {
      caseId
      initialPosition
    }
  }
`;
const createImplementationEmailRequest = gql`
  mutation createImplementationEmail($caseData: JSON) {
    createImplementationEmail(caseData: $caseData) {
      success
    }
  }
`;

export default flowRight([
  graphql<Props, SupportRequestQuery.Query, SupportRequestQuery.Variables>(supportRequestQuery, {
    options: props => ({
      variables: { topicId: props.topicId },
    }),
  }),
  graphql<Props, CreateSupportCase.Mutation, CreateSupportCase.Variables>(createSupportCase, {
    name: 'createSupportCase',
  }),
  graphql<Props, CreateImplementationEmail.Mutation, CreateImplementationEmail.Variables>(
    createImplementationEmailRequest,
    {
      name: 'createImplementationEmailRequest',
    },
  ),
  withGraphqlProgress(),
])(SupportRequest);
