// NOTE: the following was autogenerated from our ember stlyeguide by running the following script in the console
// $('.icons i.zmdi').toArray().map(i=> `"${i.className.split('zmdi-')[1]}": "\\\\${window.getComputedStyle(i,':before').content.charCodeAt(1).toString(16)}", `).join('\n')
const iconMap = {
  '3d-rotation': '\\f101',
  'airplane-off': '\\f102',
  airplane: '\\f103',
  album: '\\f104',
  archive: '\\f105',
  'assignment-account': '\\f106',
  'assignment-alert': '\\f107',
  'assignment-check': '\\f108',
  'assignment-o': '\\f109',
  'assignment-return': '\\f10a',
  'assignment-returned': '\\f10b',
  assignment: '\\f10c',
  'attachment-alt': '\\f10d',
  attachment: '\\f10e',
  audio: '\\f10f',
  'badge-check': '\\f110',
  'balance-wallet': '\\f111',
  balance: '\\f112',
  'battery-alert': '\\f113',
  'battery-flash': '\\f114',
  'battery-unknown': '\\f115',
  battery: '\\f116',
  bike: '\\f117',
  'block-alt': '\\f118',
  block: '\\f119',
  boat: '\\f11a',
  'book-image': '\\f11b',
  book: '\\f11c',
  'bookmark-outline': '\\f11d',
  bookmark: '\\f11e',
  brush: '\\f11f',
  bug: '\\f120',
  bus: '\\f121',
  cake: '\\f122',
  'car-taxi': '\\f123',
  'car-wash': '\\f124',
  car: '\\f125',
  'card-giftcard': '\\f126',
  'card-membership': '\\f127',
  'card-travel': '\\f128',
  card: '\\f129',
  'case-check': '\\f12a',
  'case-download': '\\f12b',
  'case-play': '\\f12c',
  case: '\\f12d',
  'cast-connected': '\\f12e',
  cast: '\\f12f',
  'chart-donut': '\\f130',
  chart: '\\f131',
  'city-alt': '\\f132',
  city: '\\f133',
  'close-circle-o': '\\f134',
  'close-circle': '\\f135',
  close: '\\f136',
  cocktail: '\\f137',
  'code-setting': '\\f138',
  'code-smartphone': '\\f139',
  code: '\\f13a',
  coffee: '\\f13b',
  'collection-bookmark': '\\f13c',
  'collection-case-play': '\\f13d',
  'collection-folder-image': '\\f13e',
  'collection-image-o': '\\f13f',
  'collection-image': '\\f140',
  'collection-item-1': '\\f141',
  'collection-item-2': '\\f142',
  'collection-item-3': '\\f143',
  'collection-item-4': '\\f144',
  'collection-item-5': '\\f145',
  'collection-item-6': '\\f146',
  'collection-item-7': '\\f147',
  'collection-item-8': '\\f148',
  'collection-item-9-plus': '\\f149',
  'collection-item-9': '\\f14a',
  'collection-item': '\\f14b',
  'collection-music': '\\f14c',
  'collection-pdf': '\\f14d',
  'collection-plus': '\\f14e',
  'collection-speaker': '\\f14f',
  'collection-text': '\\f150',
  'collection-video': '\\f151',
  compass: '\\f152',
  cutlery: '\\f153',
  delete: '\\f154',
  dialpad: '\\f155',
  dns: '\\f156',
  drink: '\\f157',
  edit: '\\f158',
  'email-open': '\\f159',
  email: '\\f15a',
  'eye-off': '\\f15b',
  eye: '\\f15c',
  eyedropper: '\\f15d',
  'favorite-outline': '\\f15e',
  favorite: '\\f15f',
  'filter-list': '\\f160',
  fire: '\\f161',
  flag: '\\f162',
  flare: '\\f163',
  'flash-auto': '\\f164',
  'flash-off': '\\f165',
  flash: '\\f166',
  flip: '\\f167',
  'flower-alt': '\\f168',
  flower: '\\f169',
  font: '\\f16a',
  'fullscreen-alt': '\\f16b',
  'fullscreen-exit': '\\f16c',
  fullscreen: '\\f16d',
  functions: '\\f16e',
  'gas-station': '\\f16f',
  gesture: '\\f170',
  'globe-alt': '\\f171',
  'globe-lock': '\\f172',
  globe: '\\f173',
  'graduation-cap': '\\f174',
  home: '\\f175',
  'hospital-alt': '\\f176',
  hospital: '\\f177',
  hotel: '\\f178',
  'hourglass-alt': '\\f179',
  'hourglass-outline': '\\f17a',
  hourglass: '\\f17b',
  http: '\\f17c',
  'image-alt': '\\f17d',
  'image-o': '\\f17e',
  image: '\\f17f',
  inbox: '\\f180',
  'invert-colors-off': '\\f181',
  'invert-colors': '\\f182',
  key: '\\f183',
  'label-alt-outline': '\\f184',
  'label-alt': '\\f185',
  'label-heart': '\\f186',
  label: '\\f187',
  labels: '\\f188',
  lamp: '\\f189',
  landscape: '\\f18a',
  'layers-off': '\\f18b',
  layers: '\\f18c',
  library: '\\f18d',
  link: '\\f18e',
  'lock-open': '\\f18f',
  'lock-outline': '\\f190',
  lock: '\\f191',
  'mail-reply-all': '\\f192',
  'mail-reply': '\\f193',
  'mail-send': '\\f194',
  mall: '\\f195',
  map: '\\f196',
  menu: '\\f197',
  'money-box': '\\f198',
  'money-off': '\\f199',
  money: '\\f19a',
  'more-vert': '\\f19b',
  more: '\\f19c',
  'movie-alt': '\\f19d',
  movie: '\\f19e',
  'nature-people': '\\f19f',
  nature: '\\f1a0',
  navigation: '\\f1a1',
  'open-in-browser': '\\f1a2',
  'open-in-new': '\\f1a3',
  palette: '\\f1a4',
  parking: '\\f1a5',
  'pin-account': '\\f1a6',
  'pin-assistant': '\\f1a7',
  'pin-drop': '\\f1a8',
  'pin-help': '\\f1a9',
  'pin-off': '\\f1aa',
  pin: '\\f1ab',
  pizza: '\\f1ac',
  plaster: '\\f1ad',
  'power-setting': '\\f1ae',
  power: '\\f1af',
  print: '\\f1b0',
  'puzzle-piece': '\\f1b1',
  quote: '\\f1b2',
  railway: '\\f1b3',
  receipt: '\\f1b4',
  'refresh-alt': '\\f1b5',
  'refresh-sync-alert': '\\f1b6',
  'refresh-sync-off': '\\f1b7',
  'refresh-sync': '\\f1b8',
  refresh: '\\f1b9',
  roller: '\\f1ba',
  ruler: '\\f1bb',
  scissors: '\\f1bc',
  'screen-rotation-lock': '\\f1bd',
  'screen-rotation': '\\f1be',
  'search-for': '\\f1bf',
  'search-in-file': '\\f1c0',
  'search-in-page': '\\f1c1',
  'search-replace': '\\f1c2',
  search: '\\f1c3',
  seat: '\\f1c4',
  'settings-square': '\\f1c5',
  settings: '\\f1c6',
  'shield-check': '\\f1c7',
  'shield-security': '\\f1c8',
  'shopping-basket': '\\f1c9',
  'shopping-cart-plus': '\\f1ca',
  'shopping-cart': '\\f1cb',
  'sign-in': '\\f1cc',
  'sort-amount-asc': '\\f1cd',
  'sort-amount-desc': '\\f1ce',
  'sort-asc': '\\f1cf',
  'sort-desc': '\\f1d0',
  spellcheck: '\\f1d1',
  storage: '\\f1d2',
  'store-24': '\\f1d3',
  store: '\\f1d4',
  subway: '\\f1d5',
  sun: '\\f1d6',
  'tab-unselected': '\\f1d7',
  tab: '\\f1d8',
  'tag-close': '\\f1d9',
  'tag-more': '\\f1da',
  tag: '\\f1db',
  'thumb-down': '\\f1dc',
  'thumb-up-down': '\\f1dd',
  'thumb-up': '\\f1de',
  'ticket-star': '\\f1df',
  toll: '\\f1e0',
  toys: '\\f1e1',
  traffic: '\\f1e2',
  translate: '\\f1e3',
  'triangle-down': '\\f1e4',
  'triangle-up': '\\f1e5',
  truck: '\\f1e6',
  'turning-sign': '\\f1e7',
  wallpaper: '\\f1e8',
  'washing-machine': '\\f1e9',
  'window-maximize': '\\f1ea',
  'window-minimize': '\\f1eb',
  'window-restore': '\\f1ec',
  wrench: '\\f1ed',
  'zoom-in': '\\f1ee',
  'zoom-out': '\\f1ef',
  'alert-circle-o': '\\f1f0',
  'alert-circle': '\\f1f1',
  'alert-octagon': '\\f1f2',
  'alert-polygon': '\\f1f3',
  'alert-triangle': '\\f1f4',
  'help-outline': '\\f1f5',
  help: '\\f1f6',
  'info-outline': '\\f1f7',
  info: '\\f1f8',
  'notifications-active': '\\f1f9',
  'notifications-add': '\\f1fa',
  'notifications-none': '\\f1fb',
  'notifications-off': '\\f1fc',
  'notifications-paused': '\\f1fd',
  notifications: '\\f1fe',
  'account-add': '\\f1ff',
  'account-box-mail': '\\f200',
  'account-box-o': '\\f201',
  'account-box-phone': '\\f202',
  'account-box': '\\f203',
  'account-calendar': '\\f204',
  'account-circle': '\\f205',
  'account-o': '\\f206',
  account: '\\f207',
  'accounts-add': '\\f208',
  'accounts-alt': '\\f209',
  'accounts-list-alt': '\\f20a',
  'accounts-list': '\\f20b',
  'accounts-outline': '\\f20c',
  accounts: '\\f20d',
  face: '\\f20e',
  female: '\\f20f',
  'male-alt': '\\f210',
  'male-female': '\\f211',
  male: '\\f212',
  'mood-bad': '\\f213',
  mood: '\\f214',
  run: '\\f215',
  walk: '\\f216',
  'cloud-box': '\\f217',
  'cloud-circle': '\\f218',
  'cloud-done': '\\f219',
  'cloud-download': '\\f21a',
  'cloud-off': '\\f21b',
  'cloud-outline-alt': '\\f21c',
  'cloud-outline': '\\f21d',
  'cloud-upload': '\\f21e',
  cloud: '\\f21f',
  download: '\\f220',
  'file-plus': '\\f221',
  'file-text': '\\f222',
  file: '\\f223',
  'folder-outline': '\\f224',
  'folder-person': '\\f225',
  'folder-star-alt': '\\f226',
  'folder-star': '\\f227',
  folder: '\\f228',
  gif: '\\f229',
  upload: '\\f22a',
  'border-all': '\\f22b',
  'border-bottom': '\\f22c',
  'border-clear': '\\f22d',
  'border-color': '\\f22e',
  'border-horizontal': '\\f22f',
  'border-inner': '\\f230',
  'border-left': '\\f231',
  'border-outer': '\\f232',
  'border-right': '\\f233',
  'border-style': '\\f234',
  'border-top': '\\f235',
  'border-vertical': '\\f236',
  copy: '\\f237',
  crop: '\\f238',
  'format-align-center': '\\f239',
  'format-align-justify': '\\f23a',
  'format-align-left': '\\f23b',
  'format-align-right': '\\f23c',
  'format-bold': '\\f23d',
  'format-clear-all': '\\f23e',
  'format-clear': '\\f23f',
  'format-color-fill': '\\f240',
  'format-color-reset': '\\f241',
  'format-color-text': '\\f242',
  'format-indent-decrease': '\\f243',
  'format-indent-increase': '\\f244',
  'format-italic': '\\f245',
  'format-line-spacing': '\\f246',
  'format-list-bulleted': '\\f247',
  'format-list-numbered': '\\f248',
  'format-ltr': '\\f249',
  'format-rtl': '\\f24a',
  'format-size': '\\f24b',
  'format-strikethrough-s': '\\f24c',
  'format-strikethrough': '\\f24d',
  'format-subject': '\\f24e',
  'format-underlined': '\\f24f',
  'format-valign-bottom': '\\f250',
  'format-valign-center': '\\f251',
  'format-valign-top': '\\f252',
  redo: '\\f253',
  'select-all': '\\f254',
  'space-bar': '\\f255',
  'text-format': '\\f256',
  transform: '\\f257',
  undo: '\\f258',
  'wrap-text': '\\f259',
  'comment-alert': '\\f25a',
  'comment-alt-text': '\\f25b',
  'comment-alt': '\\f25c',
  'comment-edit': '\\f25d',
  'comment-image': '\\f25e',
  'comment-list': '\\f25f',
  'comment-more': '\\f260',
  'comment-outline': '\\f261',
  'comment-text-alt': '\\f262',
  'comment-text': '\\f263',
  'comment-video': '\\f264',
  comment: '\\f265',
  comments: '\\f266',
  'check-all': '\\f267',
  'check-circle-u': '\\f268',
  'check-circle': '\\f269',
  'check-square': '\\f26a',
  check: '\\f26b',
  'circle-o': '\\f26c',
  circle: '\\f26d',
  'dot-circle-alt': '\\f26e',
  'dot-circle': '\\f26f',
  'minus-circle-outline': '\\f270',
  'minus-circle': '\\f271',
  'minus-square': '\\f272',
  minus: '\\f273',
  'plus-circle-o-duplicate': '\\f274',
  'plus-circle-o': '\\f275',
  'plus-circle': '\\f276',
  'plus-square': '\\f277',
  plus: '\\f278',
  'square-o': '\\f279',
  'star-circle': '\\f27a',
  'star-half': '\\f27b',
  'star-outline': '\\f27c',
  star: '\\f27d',
  'bluetooth-connected': '\\f27e',
  'bluetooth-off': '\\f27f',
  'bluetooth-search': '\\f280',
  'bluetooth-setting': '\\f281',
  bluetooth: '\\f282',
  'camera-add': '\\f283',
  'camera-alt': '\\f284',
  'camera-bw': '\\f285',
  'camera-front': '\\f286',
  'camera-mic': '\\f287',
  'camera-party-mode': '\\f288',
  'camera-rear': '\\f289',
  'camera-roll': '\\f28a',
  'camera-switch': '\\f28b',
  camera: '\\f28c',
  'card-alert': '\\f28d',
  'card-off': '\\f28e',
  'card-sd': '\\f28f',
  'card-sim': '\\f290',
  'desktop-mac': '\\f291',
  'desktop-windows': '\\f292',
  'device-hub': '\\f293',
  'devices-off': '\\f294',
  devices: '\\f295',
  dock: '\\f296',
  floppy: '\\f297',
  gamepad: '\\f298',
  'gps-dot': '\\f299',
  'gps-off': '\\f29a',
  gps: '\\f29b',
  'headset-mic': '\\f29c',
  headset: '\\f29d',
  'input-antenna': '\\f29e',
  'input-composite': '\\f29f',
  'input-hdmi': '\\f2a0',
  'input-power': '\\f2a1',
  'input-svideo': '\\f2a2',
  'keyboard-hide': '\\f2a3',
  keyboard: '\\f2a4',
  'laptop-chromebook': '\\f2a5',
  'laptop-mac': '\\f2a6',
  laptop: '\\f2a7',
  'mic-off': '\\f2a8',
  'mic-outline': '\\f2a9',
  'mic-setting': '\\f2aa',
  mic: '\\f2ab',
  mouse: '\\f2ac',
  'network-alert': '\\f2ad',
  'network-locked': '\\f2ae',
  'network-off': '\\f2af',
  'network-outline': '\\f2b0',
  'network-setting': '\\f2b1',
  network: '\\f2b2',
  'phone-bluetooth': '\\f2b3',
  'phone-end': '\\f2b4',
  'phone-forwarded': '\\f2b5',
  'phone-in-talk': '\\f2b6',
  'phone-locked': '\\f2b7',
  'phone-missed': '\\f2b8',
  'phone-msg': '\\f2b9',
  'phone-paused': '\\f2ba',
  'phone-ring': '\\f2bb',
  'phone-setting': '\\f2bc',
  'phone-sip': '\\f2bd',
  phone: '\\f2be',
  'portable-wifi-changes': '\\f2bf',
  'portable-wifi-off': '\\f2c0',
  'portable-wifi': '\\f2c1',
  radio: '\\f2c2',
  reader: '\\f2c3',
  'remote-control-alt': '\\f2c4',
  'remote-control': '\\f2c5',
  router: '\\f2c6',
  scanner: '\\f2c7',
  'smartphone-android': '\\f2c8',
  'smartphone-download': '\\f2c9',
  'smartphone-erase': '\\f2ca',
  'smartphone-info': '\\f2cb',
  'smartphone-iphone': '\\f2cc',
  'smartphone-landscape-lock': '\\f2cd',
  'smartphone-landscape': '\\f2ce',
  'smartphone-lock': '\\f2cf',
  'smartphone-portrait-lock': '\\f2d0',
  'smartphone-ring': '\\f2d1',
  'smartphone-setting': '\\f2d2',
  'smartphone-setup': '\\f2d3',
  smartphone: '\\f2d4',
  speaker: '\\f2d5',
  'tablet-android': '\\f2d6',
  'tablet-mac': '\\f2d7',
  tablet: '\\f2d8',
  'tv-alt-play': '\\f2d9',
  'tv-list': '\\f2da',
  'tv-play': '\\f2db',
  tv: '\\f2dc',
  usb: '\\f2dd',
  'videocam-off': '\\f2de',
  'videocam-switch': '\\f2df',
  videocam: '\\f2e0',
  watch: '\\f2e1',
  'wifi-alt-2': '\\f2e2',
  'wifi-alt': '\\f2e3',
  'wifi-info': '\\f2e4',
  'wifi-lock': '\\f2e5',
  'wifi-off': '\\f2e6',
  'wifi-outline': '\\f2e7',
  wifi: '\\f2e8',
  'arrow-left-bottom': '\\f2e9',
  'arrow-left': '\\f2ea',
  'arrow-merge': '\\f2eb',
  'arrow-missed': '\\f2ec',
  'arrow-right-top': '\\f2ed',
  'arrow-right': '\\f2ee',
  'arrow-split': '\\f2ef',
  arrows: '\\f2f0',
  'caret-down-circle': '\\f2f1',
  'caret-down': '\\f2f2',
  'caret-left-circle': '\\f2f3',
  'caret-left': '\\f2f4',
  'caret-right-circle': '\\f2f5',
  'caret-right': '\\f2f6',
  'caret-up-circle': '\\f2f7',
  'caret-up': '\\f2f8',
  'chevron-down': '\\f2f9',
  'chevron-left': '\\f2fa',
  'chevron-right': '\\f2fb',
  'chevron-up': '\\f2fc',
  forward: '\\f2fd',
  'long-arrow-down': '\\f2fe',
  'long-arrow-left': '\\f2ff',
  'long-arrow-return': '\\f300',
  'long-arrow-right': '\\f301',
  'long-arrow-tab': '\\f302',
  'long-arrow-up': '\\f303',
  'rotate-ccw': '\\f304',
  'rotate-cw': '\\f305',
  'rotate-left': '\\f306',
  'rotate-right': '\\f307',
  'square-down': '\\f308',
  'square-right': '\\f309',
  'swap-alt': '\\f30a',
  'swap-vertical-circle': '\\f30b',
  'swap-vertical': '\\f30c',
  swap: '\\f30d',
  'trending-down': '\\f30e',
  'trending-flat': '\\f30f',
  'trending-up': '\\f310',
  'unfold-less': '\\f311',
  'unfold-more': '\\f312',
  apps: '\\f313',
  'grid-off': '\\f314',
  grid: '\\f315',
  'view-agenda': '\\f316',
  'view-array': '\\f317',
  'view-carousel': '\\f318',
  'view-column': '\\f319',
  'view-comfy': '\\f31a',
  'view-compact': '\\f31b',
  'view-dashboard': '\\f31c',
  'view-day': '\\f31d',
  'view-headline': '\\f31e',
  'view-list-alt': '\\f31f',
  'view-list': '\\f320',
  'view-module': '\\f321',
  'view-quilt': '\\f322',
  'view-stream': '\\f323',
  'view-subtitles': '\\f324',
  'view-toc': '\\f325',
  'view-web': '\\f326',
  'view-week': '\\f327',
  widgets: '\\f328',
  'alarm-check': '\\f329',
  'alarm-off': '\\f32a',
  'alarm-plus': '\\f32b',
  'alarm-snooze': '\\f32c',
  alarm: '\\f32d',
  'calendar-alt': '\\f32e',
  'calendar-check': '\\f32f',
  'calendar-close': '\\f330',
  'calendar-note': '\\f331',
  calendar: '\\f332',
  'time-countdown': '\\f333',
  'time-interval': '\\f334',
  'time-restore-setting': '\\f335',
  'time-restore': '\\f336',
  time: '\\f337',
  'timer-off': '\\f338',
  timer: '\\f339',
  'android-alt': '\\f33a',
  android: '\\f33b',
  apple: '\\f33c',
  behance: '\\f33d',
  codepen: '\\f33e',
  dribbble: '\\f33f',
  dropbox: '\\f340',
  evernote: '\\f341',
  'facebook-box': '\\f342',
  facebook: '\\f343',
  'github-box': '\\f344',
  github: '\\f345',
  'google-drive': '\\f346',
  'google-earth': '\\f347',
  'google-glass': '\\f348',
  'google-maps': '\\f349',
  'google-pages': '\\f34a',
  'google-play': '\\f34b',
  'google-plus-box': '\\f34c',
  'google-plus': '\\f34d',
  google: '\\f34e',
  instagram: '\\f34f',
  'language-css3': '\\f350',
  'language-html5': '\\f351',
  'language-javascript': '\\f352',
  'language-python-alt': '\\f353',
  'language-python': '\\f354',
  lastfm: '\\f355',
  'linkedin-box': '\\f356',
  paypal: '\\f357',
  'pinterest-box': '\\f358',
  pocket: '\\f359',
  polymer: '\\f35a',
  share: '\\f35b',
  stackoverflow: '\\f35c',
  'steam-square': '\\f35d',
  steam: '\\f35e',
  'twitter-box': '\\f35f',
  twitter: '\\f360',
  vk: '\\f361',
  wikipedia: '\\f362',
  windows: '\\f363',
  'aspect-ratio-alt': '\\f364',
  'aspect-ratio': '\\f365',
  'blur-circular': '\\f366',
  'blur-linear': '\\f367',
  'blur-off': '\\f368',
  blur: '\\f369',
  'brightness-2': '\\f36a',
  'brightness-3': '\\f36b',
  'brightness-4': '\\f36c',
  'brightness-5': '\\f36d',
  'brightness-6': '\\f36e',
  'brightness-7': '\\f36f',
  'brightness-auto': '\\f370',
  'brightness-setting': '\\f371',
  'broken-image': '\\f372',
  'center-focus-strong': '\\f373',
  'center-focus-weak': '\\f374',
  compare: '\\f375',
  'crop-16-9': '\\f376',
  'crop-3-2': '\\f377',
  'crop-5-4': '\\f378',
  'crop-7-5': '\\f379',
  'crop-din': '\\f37a',
  'crop-free': '\\f37b',
  'crop-landscape': '\\f37c',
  'crop-portrait': '\\f37d',
  'crop-square': '\\f37e',
  'exposure-alt': '\\f37f',
  exposure: '\\f380',
  'filter-b-and-w': '\\f381',
  'filter-center-focus': '\\f382',
  'filter-frames': '\\f383',
  'filter-tilt-shift': '\\f384',
  gradient: '\\f385',
  grain: '\\f386',
  'graphic-eq': '\\f387',
  'hdr-off': '\\f388',
  'hdr-strong': '\\f389',
  'hdr-weak': '\\f38a',
  hdr: '\\f38b',
  iridescent: '\\f38c',
  'leak-off': '\\f38d',
  leak: '\\f38e',
  looks: '\\f38f',
  loupe: '\\f390',
  'panorama-horizontal': '\\f391',
  'panorama-vertical': '\\f392',
  'panorama-wide-angle': '\\f393',
  'photo-size-select-large': '\\f394',
  'photo-size-select-small': '\\f395',
  'picture-in-picture': '\\f396',
  slideshow: '\\f397',
  texture: '\\f398',
  tonality: '\\f399',
  vignette: '\\f39a',
  'wb-auto': '\\f39b',
  'eject-alt': '\\f39c',
  eject: '\\f39d',
  equalizer: '\\f39e',
  'fast-forward': '\\f39f',
  'fast-rewind': '\\f3a0',
  'forward-10': '\\f3a1',
  'forward-30': '\\f3a2',
  'forward-5': '\\f3a3',
  hearing: '\\f3a4',
  'pause-circle-outline': '\\f3a5',
  'pause-circle': '\\f3a6',
  pause: '\\f3a7',
  'play-circle-outline': '\\f3a8',
  'play-circle': '\\f3a9',
  play: '\\f3aa',
  'playlist-audio': '\\f3ab',
  'playlist-plus': '\\f3ac',
  'repeat-one': '\\f3ad',
  repeat: '\\f3ae',
  'replay-10': '\\f3af',
  'replay-30': '\\f3b0',
  'replay-5': '\\f3b1',
  replay: '\\f3b2',
  shuffle: '\\f3b3',
  'skip-next': '\\f3b4',
  'skip-previous': '\\f3b5',
  stop: '\\f3b6',
  'surround-sound': '\\f3b7',
  tune: '\\f3b8',
  'volume-down': '\\f3b9',
  'volume-mute': '\\f3ba',
  'volume-off': '\\f3bb',
  'volume-up': '\\f3bc',
  'n-1-square': '\\f3bd',
  'n-2-square': '\\f3be',
  'n-3-square': '\\f3bf',
  'n-4-square': '\\f3c0',
  'n-5-square': '\\f3c1',
  'n-6-square': '\\f3c2',
  'neg-1': '\\f3c3',
  'neg-2': '\\f3c4',
  'plus-1': '\\f3c5',
  'plus-2': '\\f3c6',
  'sec-10': '\\f3c7',
  'sec-3': '\\f3c8',
  zero: '\\f3c9',
  'airline-seat-flat-angled': '\\f3ca',
  'airline-seat-flat': '\\f3cb',
  'airline-seat-individual-suite': '\\f3cc',
  'airline-seat-legroom-extra': '\\f3cd',
  'airline-seat-legroom-normal': '\\f3ce',
  'airline-seat-legroom-reduced': '\\f3cf',
  'airline-seat-recline-extra': '\\f3d0',
  'airline-seat-recline-normal': '\\f3d1',
  airplay: '\\f3d2',
  'closed-caption': '\\f3d3',
  'confirmation-number': '\\f3d4',
  'developer-board': '\\f3d5',
  'disc-full': '\\f3d6',
  explicit: '\\f3d7',
  'flight-land': '\\f3d8',
  'flight-takeoff': '\\f3d9',
  'flip-to-back': '\\f3da',
  'flip-to-front': '\\f3db',
  'group-work': '\\f3dc',
  hd: '\\f3dd',
  hq: '\\f3de',
  'markunread-mailbox': '\\f3df',
  memory: '\\f3e0',
  nfc: '\\f3e1',
  'play-for-work': '\\f3e2',
  'power-input': '\\f3e3',
  'present-to-all': '\\f3e4',
  satellite: '\\f3e5',
  'tap-and-play': '\\f3e6',
  vibration: '\\f3e7',
  voicemail: '\\f3e8',
  group: '\\f3e9',
  rss: '\\f3ea',
  shape: '\\f3eb',
  spinner: '\\f3ec',
  ungroup: '\\f3ed',
  '500px': '\\f3ee',
  '8tracks': '\\f3ef',
  amazon: '\\f3f0',
  blogger: '\\f3f1',
  delicious: '\\f3f2',
  disqus: '\\f3f3',
  flattr: '\\f3f4',
  flickr: '\\f3f5',
  'github-alt': '\\f3f6',
  'google-old': '\\f3f7',
  linkedin: '\\f3f8',
  odnoklassniki: '\\f3f9',
  outlook: '\\f3fa',
  'paypal-alt': '\\f3fb',
  pinterest: '\\f3fc',
  playstation: '\\f3fd',
  reddit: '\\f3fe',
  skype: '\\f3ff',
  slideshare: '\\f400',
  soundcloud: '\\f401',
  tumblr: '\\f402',
  twitch: '\\f403',
  vimeo: '\\f404',
  whatsapp: '\\f405',
  xbox: '\\f406',
  yahoo: '\\f407',
  'youtube-play': '\\f408',
  youtube: '\\f409',
};

export type IconNameString = keyof typeof iconMap;

export default iconMap;
