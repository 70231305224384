export type ZAppInstallations = { [key: string]: string };

export enum ZAppInstallStatus {
  deleted = 0,
  disabled = 10,
  initializing = 25,
  notEnrolled = 50,
  enrolling = 100,
  ok = 200,
}
