import React, { FunctionComponent } from 'react';

import { Heading, TextBlock } from 'zbase';

import IssueSelector, { Props as IssueSelectorProps } from '../components/IssueSelector';

const IssueSelectPage: FunctionComponent<IssueSelectorProps> = props => {
  return (
    <>
      <Heading level={3} fontStyle="controls.l" mb={2}>
        Thanks for contacting Support. What can we help you with?
      </Heading>
      <TextBlock mb={2} fontStyle="paragraphs.m">
        Please select an overarching topic that aligns with what you are needing help with.
      </TextBlock>
      <IssueSelector {...props} />
    </>
  );
};

export default IssueSelectPage;
