import React, { Component } from 'react';

import { styled, theme } from 'z-frontend-theme';
import { Box, Flex } from 'zbase';

import BasePopper, { BasePopperProps, PlacementProp } from '../popover/BasePopper';
import ArrowedPopperContainer from '../popover/ArrowedPopperContainer';

export type TooltipProps = Omit<BasePopperProps, 'children' | 'event'> & {
  /**
   * @deprecated Please use InformationPopover if you want content to show on click
   *
   * Show content when the user clicks or hovers on the target element.
   * @default 'hover'
   */
  eventDeprecated?: 'click' | 'hover';
};

// For tooltips force text to be white rather than getting the default from a <TextBlock> component
const StyledPopperContainer = styled(Flex)`
  display: inline;
`;

class Tooltip extends Component<TooltipProps> {
  static defaultProps = {
    showArrow: true,
    eventDeprecated: 'hover',
  };

  render() {
    const { children, eventDeprecated, showArrow, popperModifiers: customPopperModifiers } = this.props;
    const popperModifiers: BasePopperProps['popperModifiers'] = {
      preventOverflow: {
        boundariesElement: 'viewport',
      },
      ...customPopperModifiers,
    };

    return (
      <BasePopper {...this.props} popperModifiers={popperModifiers} event={eventDeprecated}>
        {({ placement, style, ref, arrowProps }) => (
          <div style={{ ...style, zIndex: theme.zIndex.popover }} ref={ref}>
            <StyledPopperContainer>
              <ArrowedPopperContainer
                arrowProps={arrowProps}
                useDefaultPopperContainer
                dataPlacement={placement as PlacementProp}
                showArrow={showArrow}
                bg="grayscale.white"
                color="text.dark"
              >
                <Box maxWidth={[null, 500]}>{children}</Box>
              </ArrowedPopperContainer>
            </StyledPopperContainer>
          </div>
        )}
      </BasePopper>
    );
  }
}

export default Tooltip;
