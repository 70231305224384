import React, { FunctionComponent } from 'react';

import SupportRequest, { Props as SupportRequestProps } from '../components/SupportRequest';
import { openChatWindow } from '../utils/chat';

function handleGoToChat(callBack: () => void) {
  return (caseId: string, coordinatorType: string) => {
    openChatWindow(caseId, coordinatorType);
    callBack();
  };
}

const SupportRequestPage: FunctionComponent<SupportRequestProps> = props => {
  return <SupportRequest goToChat={handleGoToChat(props.onClose)} {...props} />;
};

export default SupportRequestPage;
