import React, { Component } from 'react';

import { Box, TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import { getEventLogger, BRAND_NAME } from 'z-frontend-app-bootstrap';
import { styled } from 'z-frontend-theme';

import { ContactOptionsQuery } from '../gqlTypes';
import {
  ALEGEUS_PHONE_NUM,
  BROKER_PHONE_NUM,
  BROKER_PHONE_NUM_RAW,
  DISABLE_CONTACT_BANNERS,
  IMPLEMENTATION_CONTACT_OPTIONS,
  JAZZ_HR_EMAIL,
  METHOD_ALEGEUS,
  METHOD_CHAT,
  METHOD_EMAIL,
  METHOD_PHONE,
  PHONE_NUM,
  PHONE_NUM_RAW,
  TRINET_PHONE_NUM,
} from './constants';
import RecommendedQSContactMethodCard from './RecommendedQSContactMethodCard';

type AdminDetails = {
  name?: string;
  phone?: string;
  email?: string;
};

type Props = {
  contactMethod: string;
  referenceNumber?: string;
  hrAdmin?: AdminDetails;
  brokerAdmin?: AdminDetails;
  onContactMethodClick: (topicId: string, contactMethod: string) => void;
  topicId?: string;
  path?: string;
  /**
   * @default false
   */
  isBusinessHours?: boolean;
  /**
   * @default false
   */
  bannerType?: string;
  isBroker?: boolean;
  isAllegeusBusinessHours?: boolean;
  supportAvailability: ContactOptionsQuery.SupportAvailability;
  implementationAvailability: { chat: boolean; phone?: boolean };
  isImplementationBusinessHours: boolean;
};

export const RECOMMENDED_PAGE_METHODS = {
  chat: {
    description: (isBroker: boolean) => 'Start chatting with an expert.',
    subSubDescription: 'Monday - Friday 5 AM - 4 PM PST',
    link: 'Chat with us',
    method: 'Chat',
    disableText: 'We are currently outside our designated business hours.',
    emergencyText: 'Chat Support is currently experiencing technical difficulties.',
    nonEmergencyText: 'Chat Support is not currently available. We apologize for the inconvenience',
  },
  email: {
    description: (isBroker: boolean) => 'Get an emailed response from our team.',
    link: 'Email us',
    method: 'Email',
  },
  phone: {
    description: (isBroker: boolean) => (isBroker ? BROKER_PHONE_NUM : PHONE_NUM),
    subSubDescription: 'Monday - Friday 5 AM - 4 PM  PST',
    method: 'Phone',
    disableText: 'We are currently outside our designated business hours.',
    emergencyText: 'Phone Support is currently experiencing technical difficulties.',
    nonEmergencyText: 'Phone Support is not currently available. We apologize for the inconvenience',
  },
  alegeus: {
    description: (isBroker: boolean) => ALEGEUS_PHONE_NUM,
    subSubDescription: 'Available Monday - Friday 5 AM-5 PM PST',
    method: `${BRAND_NAME} Flex Benefits`,
    disabledSubSubDescription: 'Available Monday - Friday 5 AM-5 PM PST',
  },
  hr: {
    description: (isBroker: boolean) => false,
    method: 'HR Admin',
  },
  broker: {
    description: (isBroker: boolean) => false,
    method: 'Benefits Contact',
  },
  jazzHr: {
    description: (isBroker: boolean) => JAZZ_HR_EMAIL,
    method: 'Jazz HR Integration',
  },
  trinet: {
    description: (isBroker: boolean) => (
      <Box>
        <TextBlock>Only for applicable employees on an active Enrich plan through their employer</TextBlock>
        <br />
        <TextBlock>{TRINET_PHONE_NUM}</TextBlock>{' '}
      </Box>
    ),
    method: 'TriNet Enrich',
  },
};

const CursorTextBlock = styled(TextBlock)`
  cursor: pointer;
`;

export class RecommendedContactMethodCard extends Component<Props> {
  onButtonClick = (contactMethod: string, topicId: string, path: string) => {
    if ([METHOD_CHAT, METHOD_EMAIL, METHOD_ALEGEUS].indexOf(contactMethod) > -1) {
      getEventLogger().log('support_flow_navigation', { path, method: `recommended_${contactMethod}` });
      this.props.onContactMethodClick(topicId, contactMethod);
    }
  };

  makeCallIfPhone = (isPhone: boolean, shouldDisableChatAndPhone: boolean, isBroker: boolean) => {
    if (isPhone && !shouldDisableChatAndPhone) {
      window.open(`tel:${isBroker ? BROKER_PHONE_NUM_RAW : PHONE_NUM_RAW}`);
    }
  };

  render() {
    const {
      contactMethod,
      brokerAdmin,
      hrAdmin,
      referenceNumber,
      isBusinessHours,
      isAllegeusBusinessHours,
      isBroker,
      bannerType,
      supportAvailability,
      implementationAvailability,
      isImplementationBusinessHours,
    } = this.props;
    if (IMPLEMENTATION_CONTACT_OPTIONS.includes(contactMethod)) {
      return (
        <RecommendedQSContactMethodCard
          contactMethod={contactMethod}
          topicId={this.props.topicId}
          path={this.props.path}
          implementationAvailability={implementationAvailability}
          isBusinessHours={isImplementationBusinessHours}
          onContactMethodClick={this.props.onContactMethodClick}
        />
      );
    }
    const allData = (RECOMMENDED_PAGE_METHODS as any)[contactMethod];
    const method = (allData && allData.method) || '';
    let description = (allData && allData.description(isBroker)) || '';
    let subDescription = (allData && allData.subSubdescription) || '';
    let subSubDescription = (allData && allData.subSubDescription) || '';
    const disabledSubSubDescription = (allData && allData.disabledSubSubDescription) || '';
    let link = (allData && allData.link) || '';
    const disableText = (allData && allData.disableText) || '';
    const emergencyText = (allData && allData.emergencyText) || '';
    const nonEmergencyText = (allData && allData.nonEmergencyText) || '';
    const disablingBanner = DISABLE_CONTACT_BANNERS.includes(bannerType);

    const isEmail = METHOD_EMAIL === contactMethod;
    const isPhone = METHOD_PHONE === contactMethod || METHOD_ALEGEUS === contactMethod;
    const isChat = METHOD_CHAT === contactMethod;
    const shouldDisableChat = !supportAvailability.chat;
    const shouldDisablePhone = !supportAvailability.phone;

    const shouldDisableChatAndPhone = !isBusinessHours || disablingBanner;
    const disableChat = isChat && (!isBusinessHours || disablingBanner || shouldDisableChat);
    const disablePhone = isPhone && (!isBusinessHours || disablingBanner || shouldDisablePhone);

    let shouldGrayOut = false;

    if (shouldDisableChatAndPhone) {
      if ([METHOD_CHAT, METHOD_PHONE].indexOf(contactMethod) > -1) {
        shouldGrayOut = true;
        if (disablingBanner) {
          description = emergencyText;
        } else {
          description = disableText;
        }
        link = '';
      }
    } else if (disableChat || disablePhone) {
      shouldGrayOut = true;
      description = nonEmergencyText;
    }

    if (contactMethod === METHOD_ALEGEUS && !isAllegeusBusinessHours) {
      shouldGrayOut = true;
      subSubDescription = disabledSubSubDescription;
    }

    if (brokerAdmin) {
      description = brokerAdmin.name;
      subDescription = brokerAdmin.phone;
      link = brokerAdmin.email;
    } else if (hrAdmin) {
      description = hrAdmin.name;
      subDescription = hrAdmin.phone;
      link = hrAdmin.email;
    }
    if (METHOD_PHONE === contactMethod && isBusinessHours && !disablePhone) {
      subDescription = `Reference Number: ${referenceNumber}`;
    }

    return (
      <Card id={`coverage-section-${contactMethod}`} w={1} bg={shouldGrayOut ? 'grayscale.f' : 'grayscale.white'}>
        <Card.Row>
          <TextBlock bold textAlign="left" color="text.dark" fontStyle="headings.m">
            {method}
          </TextBlock>
          <TextBlock
            onClick={() => this.makeCallIfPhone(isPhone, disablePhone, isBroker)}
            mt={2}
            mb={2}
            textAlign="left"
            color="text.default"
            fontStyle="paragraphs.m"
          >
            {description}
          </TextBlock>
          {subDescription ? (
            <TextBlock mt={2} mb={2} textAlign="left" color="text.default" fontStyle="paragraphs.m">
              {subDescription}
            </TextBlock>
          ) : (
            ''
          )}
          {subSubDescription ? (
            <TextBlock mt={2} mb={2} textAlign="left" color="text.off" fontStyle="paragraphs.m">
              {subSubDescription}
            </TextBlock>
          ) : (
            ''
          )}
          {isEmail && (
            <TextBlock mt={2} mb={2}>
              &nbsp;
            </TextBlock>
          )}
          {(isEmail || !(disableChat || disablePhone)) && (
            <CursorTextBlock
              p={0}
              fontStyle="controls.m"
              color="link.normal"
              onClick={() => this.onButtonClick(contactMethod, this.props.topicId, this.props.path)}
            >
              {link}
            </CursorTextBlock>
          )}
        </Card.Row>
      </Card>
    );
  }
}
