import React, { useEffect } from 'react';

import { PrerequisiteQuery } from '../gqlTypes';
import { subscribeToEmberMessage } from '../emberIntegration';
import { PrerequisiteType } from '../../schema/schemaTypes';
import useQueryPrerequisites from './useQueryPrerequisites';
import { markTaskComplete } from './useMarkTaskComplete';
import { MARK_TASK_COMPLETE_MESSAGE } from './constants';
import { useUserAuthenticationState, UserAuthenticationState } from '../session-timer';

export const PrerequisiteContext = React.createContext<{
  prerequisiteData: PrerequisiteQuery.Query['prerequisiteRedirect'];
  refreshPrerequisites: () => void;
} | null>(null);

const RealPrerequisiteManager: React.FunctionComponent<{}> = ({ children }) => {
  const { loading, data, refetch } = useQueryPrerequisites();
  useEffect(() => {
    if (__APP_NAME__ === 'boot') {
      subscribeToEmberMessage(
        MARK_TASK_COMPLETE_MESSAGE,
        (taskCompleteMessage: { group: string; type: PrerequisiteType }) => markTaskComplete(taskCompleteMessage),
      );
    }
  }, []);

  return (
    <PrerequisiteContext.Provider
      value={{
        prerequisiteData: loading ? null : data?.prerequisiteRedirect,
        refreshPrerequisites: refetch,
      }}
    >
      {children}
    </PrerequisiteContext.Provider>
  );
};

const FakePrerequisiteManager: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <PrerequisiteContext.Provider value={{ prerequisiteData: null, refreshPrerequisites: () => {} }}>
      {children}
    </PrerequisiteContext.Provider>
  );
};

const PrerequisiteManager: React.FunctionComponent<{}> = ({ children }) => {
  const authState = useUserAuthenticationState();

  if (authState === UserAuthenticationState.unknown) {
    return null;
  } else if (authState === UserAuthenticationState.anonymous) {
    // NOTE: for now the pre-requisite redirect doesn't support anonymous user so we avoid the queries
    return <FakePrerequisiteManager>{children}</FakePrerequisiteManager>;
  }
  // For authenticated users
  return <RealPrerequisiteManager>{children}</RealPrerequisiteManager>;
};

export default PrerequisiteManager;
