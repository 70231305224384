import React, { FunctionComponent } from 'react';

import { TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import { getEventLogger } from 'z-frontend-app-bootstrap';
import { styled } from 'z-frontend-theme';

import { IMPLEMENTATION_CONTACT_METHODS, METHOD_QUICKSTART_CHAT, METHOD_QUICKSTART_EMAIL } from './constants';

type ImplementationAvailability = {
  /**
   * @default false
   */
  chat: boolean;
};

type Props = {
  contactMethod: string;
  onContactMethodClick: (topicId: string, contactMethod: string) => void;
  topicId?: string;
  path?: string;
  /**
   * @default false
   */
  isBusinessHours?: boolean;
  implementationAvailability?: ImplementationAvailability;
};
const CursorTextBlock = styled(TextBlock)`
  cursor: pointer;
`;

const RecommendedQSContactMethodCard: FunctionComponent<Props> = props => {
  const onButtonClick = (
    contactMethod: string,
    topicId: string,
    path: string,
    onContactMethodClick: (topicId: string, contactMethod: string) => void,
  ) => {
    if ([METHOD_QUICKSTART_CHAT, METHOD_QUICKSTART_EMAIL].indexOf(contactMethod) > -1) {
      getEventLogger().log('implementation_support_flow_navigation', { path, method: `recommended_${contactMethod}` });
      onContactMethodClick(topicId, contactMethod);
    }
  };
  const { contactMethod, topicId, path, implementationAvailability, isBusinessHours, onContactMethodClick } = props;
  const allData = (IMPLEMENTATION_CONTACT_METHODS as any)[contactMethod];
  let { description } = allData;
  const { subDescription, method, link, disableText, nonEmergencyText } = allData;
  const shouldDisableChat = !implementationAvailability.chat;
  const isEmail = contactMethod === METHOD_QUICKSTART_EMAIL;
  const isChat = contactMethod === METHOD_QUICKSTART_CHAT;
  const disableChat = isChat && (!isBusinessHours || shouldDisableChat);
  const shouldGrayOut = disableChat;
  if (isChat && disableChat) {
    description = !isBusinessHours ? disableText : nonEmergencyText;
  }

  return (
    <Card id={`coverage-section-${contactMethod}`} w={380} bg={shouldGrayOut ? 'grayscale.f' : 'grayscale.white'}>
      <Card.Row>
        <TextBlock bold textAlign="left" color="text.dark" fontStyle="headings.m">
          {method}
        </TextBlock>
        <TextBlock mt={2} mb={2} textAlign="left" color="text.default" fontStyle="paragraphs.m">
          {description}
        </TextBlock>
        {subDescription ? (
          <TextBlock mt={2} mb={2} textAlign="left" color="text.default" fontStyle="paragraphs.m">
            {subDescription}
          </TextBlock>
        ) : (
          ''
        )}
        {isEmail && (
          <TextBlock mt={2} mb={2}>
            &nbsp;
          </TextBlock>
        )}
        {(isEmail || !disableChat) && (
          <CursorTextBlock
            p={0}
            fontStyle="controls.m"
            color="link.normal"
            onClick={() => onButtonClick(contactMethod, topicId, path, onContactMethodClick)}
          >
            {link}
          </CursorTextBlock>
        )}
      </Card.Row>
    </Card>
  );
};
export default RecommendedQSContactMethodCard;
