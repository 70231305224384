import React from 'react';

import { TextBlock, TextInline } from 'zbase';
import { Banner, Card } from 'z-frontend-composites';
import { Button, Link } from 'z-frontend-elements';

import { SupportFlowPages, SupportFlowPageProps } from './types';

type Props = SupportFlowPageProps;

const helpCenterUrl = `https://help.zenefits.com`;
const trainingUrl = `https://training.zenefits.com`;

const urlAndDescription = [
  {
    word: 'Help Center',
    url: helpCenterUrl,
    description: 'Find answers to your questions by exploring the knowledge base.',
  },
  { word: 'Training Portal', url: trainingUrl, description: 'Learn how to use the platform by taking online courses.' },
];

const WelcomeHub: React.FunctionComponent<Props> = props => {
  const { navigateTo } = props;
  return (
    <Card border={false} bg="grayscale.g">
      <Card.Row bg="secondary.a" textAlign="left" pt={2} pb={2} my={2} style={{ borderRadius: 4 }}>
        <TextBlock mt={2} color="grayscale.white" fontStyle="headings.m">
          <TextInline bold>Choose a solution that best fits you:</TextInline>
        </TextBlock>

        {urlAndDescription.map(item => (
          <TextBlock key={item.word} mt={2} color="grayscale.white" fontStyle="headings.s">
            <Link href={item.url} target="_blank" color="grayscale.white">
              <TextInline style={{ textDecoration: 'underline' }}>{item.word}</TextInline>
            </Link>{' '}
            <br />
            {item.description}
          </TextBlock>
        ))}
      </Card.Row>
      <Card.Row bg="grayscale.white" textAlign="left" pt={2} pb={2} my={3} style={{ borderRadius: 4 }}>
        <TextBlock my={2} fontStyle="controls.l">
          Contact Customer Support
        </TextBlock>
        <TextBlock my={2} fontStyle="paragraphs.l">
          Our customer support team is happy to help! Please click the button to create a support ticket.
        </TextBlock>
        <Button width={1} my={2} px={3} py={2} onClick={() => navigateTo(SupportFlowPages.issueSelect)}>
          Create Support Ticket
        </Button>
      </Card.Row>
      <Banner isClosable={false} type="info">
        The search functionality has been temporarily disabled. Use the above options to access help resources.
      </Banner>
    </Card>
  );
};

export default WelcomeHub;
