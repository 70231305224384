import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { useQueryWithProgress, useSwitches } from 'z-frontend-network';
import { Box, Flex, Heading, TextBlock } from 'zbase';

import { RecommendedContactMethodCard } from './RecommendedContactMethodCard';
import { METHOD_BROKER_ADMIN, METHOD_HR_ADMIN } from './constants';
import { ContactOptionsQuery } from '../gqlTypes';
import { SupportFlowPages, SupportFlowPageProps } from '../support-flow/types';

type Props = { topicId?: string } & SupportFlowPageProps;

export const useQueryContactOptions = (topicId: string) => {
  const { Loading, QueryError, data } = useQueryWithProgress<ContactOptionsQuery.Query, ContactOptionsQuery.Variables>(
    contactOptionsQuery,
    { variables: { topicId } },
  );

  // Don't compute data if we're still loading or encountered an error
  if (Loading || QueryError) {
    return { Loading, QueryError };
  }

  const {
    contactOptions,
    businessHoursSupport,
    allegeusBusinessHours,
    banner,
    decisionTreePath,
    businessHours,
    dashboard: { supportAvailability },
  } = data;
  const recommended = contactOptions && contactOptions.recommended;
  let hrAdmin = null;
  let brokerAdmin = null;
  if (recommended === METHOD_HR_ADMIN) {
    hrAdmin = contactOptions.hr;
  }
  if (recommended === METHOD_BROKER_ADMIN) {
    brokerAdmin = contactOptions.broker;
  }

  const availableMethods = contactOptions?.available;
  const nonRecommendedAvailableMethods = availableMethods
    ? availableMethods.filter((method: string) => method !== recommended)
    : [];
  return {
    data: {
      availableMethods,
      nonRecommendedAvailableMethods,
      hrAdmin,
      brokerAdmin,
      banner,
      decisionTreePath,
      recommended,
      supportAvailability,
      referenceNumber: contactOptions?.phoneref,
      isRecommended: recommended && recommended.length > 0,
      hasRecommenedMethods: nonRecommendedAvailableMethods && nonRecommendedAvailableMethods.length > 0,
      isBusinessHours: businessHoursSupport?.isBusinessHours,
      isAllegeusBusinessHours: allegeusBusinessHours?.isBusinessHours,
      isImplementationBusinessHours: businessHours?.isBusinessHours,
    },
  };
};

const SupportRecommendations: FunctionComponent<Props> = props => {
  const { topicId, navigateTo } = props;
  const switches = useSwitches();

  const { Loading, QueryError, data } = useQueryContactOptions(topicId);
  if (Loading || QueryError || !data) {
    return Loading || QueryError;
  }

  const {
    referenceNumber,
    brokerAdmin,
    recommended,
    hasRecommenedMethods,
    nonRecommendedAvailableMethods,
    hrAdmin,
    isBusinessHours,
    isAllegeusBusinessHours,
    banner,
    decisionTreePath,
    isRecommended,
    supportAvailability,
    isImplementationBusinessHours,
  } = data;
  const implementationAvailability = { chat: !switches.disable_implementation_chat };
  return (
    <Box>
      <Heading level={2} textAlign="left" color="text.dark" fontStyle="headings.xl" mb={3}>
        Recommended Support
      </Heading>
      <TextBlock textAlign="left" color="text.default" fontStyle="paragraphs.m" mb={3}>
        Based on the information we know about you, we recommend this form of support communication first.
      </TextBlock>
      {isRecommended && (
        <RecommendedContactMethodCard
          contactMethod={recommended}
          topicId={topicId}
          referenceNumber={referenceNumber}
          brokerAdmin={brokerAdmin}
          hrAdmin={hrAdmin}
          isBusinessHours={isBusinessHours}
          supportAvailability={supportAvailability}
          implementationAvailability={implementationAvailability}
          isImplementationBusinessHours={isImplementationBusinessHours}
          isAllegeusBusinessHours={isAllegeusBusinessHours}
          bannerType={banner.type}
          path={decisionTreePath.path}
          onContactMethodClick={(topicId: string, method: string) =>
            navigateTo(SupportFlowPages.submitRequest, { method, topicId })
          }
        />
      )}

      {hasRecommenedMethods && (
        <TextBlock textAlign="left" color="text.default" fontStyle="paragraphs.xl" my={3}>
          Other support contact options:
        </TextBlock>
      )}
      {!isRecommended && (
        <TextBlock textAlign="left" color="text.default" fontStyle="paragraphs.xl" mb={5}>
          We have a few different resources for reaching out to us regarding your specific topic.
        </TextBlock>
      )}
      <Flex flex="1" direction="column">
        {nonRecommendedAvailableMethods.map((method: string) => (
          <Flex key={method} flex="1">
            <RecommendedContactMethodCard
              contactMethod={method}
              topicId={topicId}
              referenceNumber={referenceNumber}
              isBusinessHours={isBusinessHours}
              isAllegeusBusinessHours={isAllegeusBusinessHours}
              bannerType={banner.type}
              supportAvailability={supportAvailability}
              implementationAvailability={implementationAvailability}
              isImplementationBusinessHours={isImplementationBusinessHours}
              path={decisionTreePath.path}
              onContactMethodClick={(topicId: string, method: string) =>
                navigateTo(SupportFlowPages.submitRequest, { method, topicId })
              }
            />
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export const contactOptionsQuery = gql`
  query contactOptionsQuery($topicId: ID) {
    dashboard {
      id
      supportAvailability {
        chat
        phone
      }
    }
    contactOptions(topicId: $topicId) {
      phoneref
      available
      recommended
      hr {
        name
        email
        phone
      }
      broker {
        name
        email
        phone
      }
    }
    decisionTreePath(topicId: $topicId) {
      path
    }
    businessHoursSupport {
      isBusinessHours
    }
    businessHours {
      isBusinessHours
    }
    allegeusBusinessHours {
      isBusinessHours
    }
    banner {
      show
      type
    }
    roleAndCategory {
      isBroker
    }
  }
`;

export default SupportRecommendations;
