import { Analytics } from '@segment/analytics-next';

type Location = { country: string; state: string };

const USCountryCode = 'US';

const isNewUSState = (newLocation: Location, existingLocations: Location[]) => {
  if (newLocation?.country !== USCountryCode || !newLocation?.state) {
    return false;
  }

  if (!existingLocations) {
    return true;
  }

  const isExisting = existingLocations.some(
    existingLocation => existingLocation.country === USCountryCode && existingLocation.state === newLocation.state,
  );

  return !isExisting;
};

/**
 * return: function
 */
const getShouldTrackNewUSState = (namespace: ExtendedAnalytics) => {
  /**
   * return: boolean - true if there is a new US state added, false otherwise.
   *
   * This function tracks an event in Segment and also returns whether a new work location state is added.
   */
  const shouldTrackNewUSState = (newLocation: Location, existingLocations: Location[]) => {
    if (isNewUSState(newLocation, existingLocations)) {
      namespace.zenefits?.track?.('New US State Added In Work Locations', { state: newLocation.state });

      return true;
    }

    return false;
  };

  return shouldTrackNewUSState;
};

type TrackFunction = Analytics['track'];

type TrackFunctionParams = Parameters<TrackFunction>;

const getTrack = (originalTrack: TrackFunction): TrackFunction => {
  return (...args: TrackFunctionParams) => {
    const now = new Date();
    const zTimestamp = now.toISOString();
    const [eventName, properties, ...rest] = args;
    const newProperties = { ...properties, zTimestamp };

    return originalTrack(eventName, newProperties, ...rest);
  };
};

type ExtendedAnalytics = Analytics & {
  zenefits?: {
    shouldTrackNewUSState?: (newLocation: Location, existingLocations: Location[]) => boolean;
    track?: TrackFunction;
  };
};

export function initializeGlobalUtils(namespace: ExtendedAnalytics) {
  if (!namespace) return;

  /**
   * Modify track function to add a zTimestamp in the properties so that we can use it to create custom time filters
   * when creating audiences on segment.com.
   */
  const originalTrack = namespace.track;
  namespace.track = getTrack(originalTrack);

  namespace.zenefits = namespace.zenefits || {};

  namespace.zenefits.shouldTrackNewUSState = getShouldTrackNewUSState(namespace);
}
