import React, { Component, ConsumerProps } from 'react';

import { NotificationContext, NotificationContextProps } from './NotificationProvider';

type NotificationManagerProps = ConsumerProps<NotificationContextProps>;

// wrap in component for documentation purposes
/**
 * @deprecated - use `useNotifications` instead
 */
class NotificationManagerDeprecated extends Component<NotificationManagerProps> {
  render() {
    return <NotificationContext.Consumer {...this.props} />;
  }
}

export default NotificationManagerDeprecated;
