import gql from 'graphql-tag';
import React, { useContext, FunctionComponent } from 'react';

import { useQuery } from 'react-apollo';

import { JSON } from './gqlTypes';

const featuresQuery = gql`
  query FeaturesQuery {
    dashboard(id: "me") {
      id
      features
    }
  }
`;

export type FeaturesContextProps = {
  features: JSON | null;
  areFeaturesLoaded: boolean;
  // If we're note in an employee context (ex: company-hub ) we can't fetch features
  canFetchFeatures: boolean;
};

const nullFeaturesContext: FeaturesContextProps = {
  features: null,
  areFeaturesLoaded: false,
  canFetchFeatures: true,
};

export const FeaturesContext = React.createContext<FeaturesContextProps>(nullFeaturesContext);

const useFetchfeatures = (noCompanyInContext: boolean): FeaturesContextProps => {
  const { data, loading } = useQuery(featuresQuery, {
    context: { headers: { 'IS-BACKGROUND-QUERY': true } },
    skip: noCompanyInContext,
  });

  if (noCompanyInContext) {
    return { features: null, areFeaturesLoaded: true, canFetchFeatures: false };
  }

  if (!data || loading) {
    return { features: null, areFeaturesLoaded: !loading, canFetchFeatures: true };
  }

  const parsedData = data?.dashboard?.features;
  return { areFeaturesLoaded: !loading, features: parsedData, canFetchFeatures: true };
};

export function useFeatures() {
  const featureProps = useContext<FeaturesContextProps>(FeaturesContext);
  return featureProps;
}

const FeatureProvider: FunctionComponent<{ noCompanyInContext: boolean }> = props => {
  const featureProps = useFetchfeatures(props.noCompanyInContext);

  return <FeaturesContext.Provider value={featureProps}>{props.children}</FeaturesContext.Provider>;
};

export default FeatureProvider;
