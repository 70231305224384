import React, { createContext, useState } from 'react';
import gql from 'graphql-tag';

import { addBootCallback, visitorTypes } from './boot-segment';
import { getApollo } from '../getApollo';
import { SegmentQuery, SegmentVisitorType } from '../gqlTypes';

type SegmentContextProps = {
  visitorTraits?: { [key: string]: string | number | boolean };
  loadingState: LoadingState;
  loadData?: () => void;
};

const segmentQuery = gql`
  query SegmentQuery($visitorType: SegmentVisitorType!) {
    dashboard {
      id
      employee {
        id
        segmentTraits(visitorType: $visitorType)
      }
    }
  }
`;

type LoadingState = 'notStarted' | 'loading' | 'loaded';

export const SegmentContext = createContext<SegmentContextProps>({ loadingState: 'notStarted' });

export const SegmentProvider: React.FC<{}> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>('notStarted');
  const [visitorTraits, setVisitorTraits] = useState();

  const loadData = () => {
    if (loadingState === 'notStarted') {
      setLoadingState('loading');
      addBootCallback(async (traits: { [key: string]: any }) => {
        const visitorType = Object.keys(traits).find(key => Object.keys(visitorTypes).includes(key));
        if (visitorType) {
          const response = await getApollo().query<SegmentQuery.Query, SegmentQuery.Variables>({
            query: segmentQuery,
            variables: { visitorType: visitorType as SegmentVisitorType },
          });
          setVisitorTraits(response.data.dashboard.employee?.segmentTraits);
        }
        setLoadingState('loaded');
      });
    }
  };

  return (
    <SegmentContext.Provider value={{ visitorTraits, loadingState, loadData }}>{children}</SegmentContext.Provider>
  );
};
