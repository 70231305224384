import boxShadows from './boxShadows';

const depthBorder = '1px solid rgba(18,52,102,0.15)';

export default [
  `border: ${depthBorder};`,
  `border: ${depthBorder}; ${boxShadows[1]}`,
  `border: ${depthBorder}; ${boxShadows[2]}`,
  // Header depth - keep last
  boxShadows[boxShadows.length - 1],
];
