import gql from 'graphql-tag';
import { produce } from 'immer';
import { MutationUpdaterFn } from 'apollo-client';

import { PrerequisiteType } from '../../schema/schemaTypes';
import { CompletePrerequisiteRedirectTask, PrerequisiteQuery } from '../gqlTypes';
import { prerequisiteQuery } from './useQueryPrerequisites';
import { getApollo } from '../getApollo';
import { MARK_TASK_COMPLETE_MESSAGE } from './constants';

const markPrerequisiteTaskComplete = gql`
  mutation CompletePrerequisiteRedirectTask($type: PrerequisiteType!, $group: String!) {
    completePrerequisiteRedirectTask(type: $type, group: $group)
  }
`;

const mutationUpdate: MutationUpdaterFn<CompletePrerequisiteRedirectTask.Mutation> = (cache, result) => {
  try {
    const prereqs = cache.readQuery<PrerequisiteQuery.Query>({ query: prerequisiteQuery });
    if (prereqs) {
      const updatedData = produce(prereqs, draft => {
        draft.prerequisiteRedirect = draft.prerequisiteRedirect.filter(
          (prerequsite: { id: string }) => prerequsite.id !== result.data?.completePrerequisiteRedirectTask?.id,
        );
      });

      cache.writeQuery({ data: updatedData, query: prerequisiteQuery });
    }
  } catch (e) {
    // In this case we haven't fetched prereqs yet so they aren't in the cache. That's ok just continue
  }
};

export function markTaskComplete(mutationVariables: { group: string; type: PrerequisiteType }) {
  return getApollo().mutate({
    mutation: markPrerequisiteTaskComplete,
    variables: mutationVariables,
    update: mutationUpdate,
  });
}

export default function(mutationVariables: { group: string; type: PrerequisiteType }) {
  // If we're not embedded or in boot (boot is always embedded) perform the update.
  if (!window.__WITHIN_EMBER_APP__ || __APP_NAME__ === 'boot') {
    return async () => {
      return markTaskComplete(mutationVariables);
    };
  } else {
    // Else send a message to boot to take care of it
    return window.sendMessageToReact('boot', MARK_TASK_COMPLETE_MESSAGE, mutationVariables);
  }
}
