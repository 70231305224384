import React, { FunctionComponent } from 'react';

import { Flex, Heading, TextBlock } from 'zbase';
import { Card } from 'z-frontend-composites';
import { Button } from 'z-frontend-elements';

import { SupportFlowPageProps } from '../support-flow/types';

type AllProps = SupportFlowPageProps;

const RequestReceived: FunctionComponent<AllProps> = ({ onClose }) => {
  return (
    <Card>
      <Card.Header>
        <Heading level={2} color="text.dark" fontStyle="headings.l">
          Thank you
        </Heading>
      </Card.Header>
      <Card.Row>
        <TextBlock>We'll reach out to you as soon as we receive your message.</TextBlock>
      </Card.Row>
      <Card.Footer>
        <Flex justify="flex-end">
          <Button mode="primary" onClick={onClose}>
            OK
          </Button>
        </Flex>
      </Card.Footer>
    </Card>
  );
};

export default RequestReceived;
