import { images as deel } from './deel';

const trinetBigLogo202311 = require('./images/trinet-logo-202311.svg');
const trinetSmallLogo202311 = require('./images/trinet-small-logo-202311.svg');

const zenefits: Record<string, any> = {
  trinetBigLogo202311,
  trinetSmallLogo202311,
};

const themes: Record<string, Record<string, any>> = { deel, zenefits };

export default themes;
