import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';

import { getApollo } from './getApollo';
import { zen } from '..';
import { SubscriptionInfoQuery } from './gqlTypes';
import { assignPendoIds, getPendoIdsIfInitialized } from './pendo/utils';
import { ZAppInstallations, ZAppInstallStatus } from './types';

// Only add subscription info about
const ZAPPS_ALLOW_LIST = {
  '1.com.zenefits.F01kAdmin': 'F01kAdmin', // Constants for zapps currently live in z-frontend-layout which we can't depend on here. Probably should move to app-bootstrap
} as { [key: string]: string };

const query = gql`
  query SubscriptionInfoQuery($appUniqueIds: [String!]) {
    activeBillingDetails {
      renewalDate
      isRenewingContract
      version
      system_version
      planDetails {
        name
        displayName
        numSeats
        contractLength
        contractEndDate
      }
      optionalProducts {
        name
        displayName
        contractEndDate
        contractLength
      }
    }
    dashboard {
      id
      zAppInstallSubscriptions(appUniqueIds: $appUniqueIds) {
        id
        appInstall {
          id
          status
          app {
            id
            uniqueId
          }
        }
      }
    }
  }
`;

function getZapps(zAppInstallSubscriptions: SubscriptionInfoQuery.ZAppInstallSubscriptions[]) {
  return zAppInstallSubscriptions.reduce((memo, zAppInstallSubscription) => {
    const { appInstall } = zAppInstallSubscription;
    if (appInstall && appInstall.app && ZAPPS_ALLOW_LIST[appInstall.app.uniqueId as string]) {
      memo[ZAPPS_ALLOW_LIST[appInstall.app.uniqueId as string]] = ZAppInstallStatus[appInstall.status as number];
    }
    return memo;
  }, {} as ZAppInstallations);
}

const getSubscriptionInfoFromPromise = async (promise: SubscriptionInfoPromise) => {
  const { data } = await promise;

  if (!data) {
    return;
  }
  const billingDetails = data.activeBillingDetails;
  const planDetails = billingDetails?.planDetails;
  const { zAppInstallSubscriptions } = data.dashboard;
  return {
    billingDetails,
    tier: planDetails?.name,
    addons: billingDetails?.optionalProducts?.map(addon => addon?.name),
    isRenewing: billingDetails.isRenewingContract,
    numEmployees: planDetails?.numSeats,
    contractLength: planDetails?.contractLength,
    renewalDate: billingDetails?.renewalDate,
    isAdmin: true,
    zappInstallations: getZapps(zAppInstallSubscriptions as SubscriptionInfoQuery.ZAppInstallSubscriptions[]),
  };
};

async function getSubscriptionInfo() {
  promiseCache = getApollo().query<SubscriptionInfoQuery.Query>({
    query,
    context: { headers: { 'IS-BACKGROUND-QUERY': true } },
    variables: { appUniqueIds: Object.keys(ZAPPS_ALLOW_LIST) },
  });

  return getSubscriptionInfoFromPromise(promiseCache);
}
type SubscriptionInfo = PromiseVal<ReturnType<typeof getSubscriptionInfo>>;

export function getDomainName(hostname: String) {
  // takes TLD + domain name if available or whatever is closest
  return hostname
    .split('.')
    .splice(-2)
    .join('.');
}

export type SubscriptionInfoWithIds = SubscriptionInfo & IDs;
type SubscriptionInfoPromise = Promise<ApolloQueryResult<SubscriptionInfoQuery.Query>>;
let promiseCache: SubscriptionInfoPromise;

function setCookie(subscriptionInfoWithIds: Partial<SubscriptionInfoWithIds>) {
  const minutes = 60 * 60 * 1000;
  const sixtyDays = 60 * 24 * minutes;
  const expiresInMs = new Date().getTime() + sixtyDays;
  const expires = new Date(expiresInMs);

  const opts = {
    expires,
    domain: getDomainName(window.location.hostname),
    path: '/',
  };
  const escapedData = window.encodeURIComponent(JSON.stringify(subscriptionInfoWithIds));
  zen.cookie.set('subscriptionInfo', escapedData, opts);
}

type IDs = { accountId?: string; visitorId?: string };

export const getSubscriptionSkusFromInfo = (subscriptionInfo: Partial<SubscriptionInfoWithIds> | undefined) => {
  if (!subscriptionInfo) {
    return [];
  }

  const result = [];

  if (subscriptionInfo.tier) {
    result.push(subscriptionInfo.tier);
  }

  if (subscriptionInfo.addons) {
    subscriptionInfo.addons.forEach(addon => {
      if (addon) {
        result.push(addon);
      }
    });
  }

  return result;
};

/**
 * Get an array of SKUs from subscription info.
 */
export const getSubscriptionSkus = async (isAdmin: boolean | null | undefined) => {
  const subscriptionInfo = await storeAndReturnSubscriptionInfo({ isAdmin: !!isAdmin });
  const subscriptionSkus = getSubscriptionSkusFromInfo(subscriptionInfo);
  return subscriptionSkus;
};

export const getZappInstallations = async (isAdmin: boolean | null | undefined) => {
  const subscriptionInfo = (await storeAndReturnSubscriptionInfo({ isAdmin: !!isAdmin })) as SubscriptionInfoWithIds;
  return subscriptionInfo?.zappInstallations;
};

const storeAndReturnSubscriptionInfo = async ({ isAdmin }: { isAdmin?: boolean }) => {
  // NOTE: only admins can access billing information so we shortcircuit this early
  if (!isAdmin) return;

  const subscriptionInfo = promiseCache
    ? await getSubscriptionInfoFromPromise(promiseCache)
    : await getSubscriptionInfo();
  const pendoIds = getPendoIdsIfInitialized();
  const subscriptionInfoWithIds = { ...subscriptionInfo, ...pendoIds };
  setCookie(subscriptionInfoWithIds);

  // It's possible pendo hasn't initialized at this point so update the cookie once it's done
  if (!pendoIds) {
    assignPendoIds(setIdsOnSubscriptionInfo);
  }

  return subscriptionInfoWithIds;
};

async function setIdsOnSubscriptionInfo(ids: IDs) {
  if (promiseCache) {
    const subscriptionInfo = await getSubscriptionInfoFromPromise(promiseCache);
    const newData = { ...subscriptionInfo, ...ids };
    setCookie(newData);
  }
}

export default storeAndReturnSubscriptionInfo;
