import qs from 'qs';

type QueryParams = {
  /**
   * the name of the ember route or a url that starts with a '/'
   */
  to: string;
  routeParams?: (string | number)[];
  queryParams?: string;
};

export type QueryParamsProps = {
  /**
   * the name of the ember route or a url that starts with a '/'
   */
  to: string;
  routeParams?: (string | number)[];
  queryParams?: { [key: string]: string | number };
  companyId?: string;
};

export default function buildEmberRouteRedirect(params: QueryParamsProps): string {
  const { to, routeParams, queryParams, companyId } = params;

  const routeQueryParams: QueryParams = { to };
  if (routeParams) {
    routeQueryParams.routeParams = routeParams;
  }
  if (queryParams) {
    routeQueryParams.queryParams = JSON.stringify(queryParams);
  }
  const dashboardRoutePrefix = `/dashboard/${companyId ? `?company=${companyId}/` : ''}#/redirect-to-route?`;
  return `${dashboardRoutePrefix}${qs.stringify(routeQueryParams, {
    arrayFormat: 'repeat',
  })}`;
}
