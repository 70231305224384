import React, { FunctionComponent } from 'react';

import { styled } from 'z-frontend-theme';
import { LinkButton } from 'z-frontend-elements';
import { color } from 'z-frontend-theme/utils';
import { Box, Flex } from 'zbase';

export type SolvvyVersion = 'v4' | 'v5';

// z-index to be higher than Solvvy window
const BaseFixedContainer = styled.div<{ solvvyVersion: SolvvyVersion }>`
  position: fixed;
  z-index: 1900000001;
`;

const V5FixedContainer = styled(BaseFixedContainer)`
  bottom: 100px;
  right: 20px;
`;

const V4FixedContainer = styled(BaseFixedContainer)`
  bottom: 20px;
  right: 0;

  @media (max-height: 600px) {
    bottom: 0px;
    top: 0px;
  }
`;

const BaseStyledContent = styled.div<{ solvvyVersion: SolvvyVersion }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: ${color('grayscale.white')};
  background-color: ${color('grayscale.g')};
`;

const V5StyledContent = styled<{ containerHeight?: number }>(BaseStyledContent)`
  display: none;
  height: ${props => (props.containerHeight ? `${props.containerHeight}px` : '70vh')};
  width: 400px;
  border-radius: 6px;
  border: 3px solid ${color('secondary.a')};

  &.show-window {
    display: flex;
  }
`;

const V4StyledContent = styled<{ containerHeight?: number }>(BaseStyledContent)`
  width: 0px;
  height: ${props => (props.containerHeight ? `${props.containerHeight}px` : '550px')};
  transition: width 0.3s ease-in-out;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.75);

  &.show-window {
    width: 500px;
    transition: width 0s;
  }

  @media (max-height: 600px) {
    height: 100vh;
    bottom: 0;
  }
`;

const TopBar = styled.div<{ solvvyVersion?: SolvvyVersion }>`
  color: #${color('grayscale.white')};
  background-color: ${color('secondary.a')};
  padding: 8px 25px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 16px;
`;

const TopBarButton = styled(LinkButton)<{ solvvyVersion?: number }>`
  font-size: ${props => (props.solvvyVersion === 'v5' ? '14px' : '16px')};
  &:hover {
    opacity: 0.8 !important;
    color: #${color('grayscale.white')} !important;
  }
`;

// Copy the Solvvy chevron styles
const BackButton = styled<{ solvvyVersion?: SolvvyVersion }>(TopBarButton)`
  margin-right: auto;
  &::before {
    content: '';
    display: inline-block;
    position: relative;
    height: ${props => (props.solvvyVersion === 'v5' ? '8px' : '12px')};
    width: ${props => (props.solvvyVersion === 'v5' ? '8px' : '12px')};
    left: -3px;
    vertical-align: top;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: rotate(225deg);
  }
`;

const MainContent = styled(Box)`
  overflow: auto;
  flex-grow: 1;
`;

type SolvvyWindowProps = {
  onBackClick?: () => void;
  onCloseClick: () => void;
  show?: boolean;
  solvvyVersion: SolvvyVersion; // Once we're done migrating to v5 we can remove this.
  containerHeight?: number;
};

const ContainerComponents = {
  v4: { fixedContainer: V4FixedContainer, styledContent: V4StyledContent },
  v5: { fixedContainer: V5FixedContainer, styledContent: V5StyledContent },
};

const SolvvyWindow: FunctionComponent<SolvvyWindowProps> = ({
  children,
  onBackClick,
  onCloseClick,
  show,
  solvvyVersion,
}) => {
  const containerComponents = ContainerComponents[solvvyVersion] || ContainerComponents.v4;

  const FixedContainer = containerComponents.fixedContainer;
  const StyledContent = containerComponents.styledContent;
  return (
    <FixedContainer solvvyVersion={solvvyVersion}>
      <StyledContent solvvyVersion={solvvyVersion} className={show ? 'show-window' : ''}>
        <TopBar solvvyVersion={solvvyVersion}>
          <Flex justify="flex-end">
            {onBackClick && (
              <BackButton solvvyVersion={solvvyVersion} onClick={onBackClick} color="grayscale.white">
                Back
              </BackButton>
            )}
            {solvvyVersion === 'v4' && (
              <TopBarButton onClick={onCloseClick} color="grayscale.white">
                Close
              </TopBarButton>
            )}
          </Flex>
        </TopBar>
        <MainContent px={3} py={2}>
          {show && children}
        </MainContent>
      </StyledContent>
    </FixedContainer>
  );
};

export default SolvvyWindow;
