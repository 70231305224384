import React, { createContext } from 'react';

import { useUserAuthenticationState, UserAuthenticationState } from 'z-frontend-app-bootstrap';
import { LoadingScreen } from 'z-frontend-elements';

type AnonymousUserContextProps = {
  isAnonymousUser: boolean;
};

export const AnonymousUserContext = createContext<AnonymousUserContextProps>({
  isAnonymousUser: false,
});

const AnonymousUserProvider: React.FC<{}> = ({ children }) => {
  const authenticationState = useUserAuthenticationState();
  const isAnonymousUser = authenticationState === UserAuthenticationState.anonymous;
  return (
    <AnonymousUserContext.Provider value={{ isAnonymousUser }}>
      {authenticationState === UserAuthenticationState.unknown ? <LoadingScreen /> : children}
    </AnonymousUserContext.Provider>
  );
};

export default AnonymousUserProvider;
