export const guides = {
  advisoryServicesUpsell: '0dxAStGNm_Q2E4Ze0cd45-7Nr5k',
  demoCenter: {
    overview: 'TGuif0_d-eb2MPR4jSW_fFCfKJc',
    resourceCenter: 'iirETV6LReyR7Y5qX48YRoiUU8U',
  },
  onboardingIntroVideoHr: 'GkSUZ_Z4XnwyvW7uj_jUD-ggqBE',
  onboardingIntroVideoPayroll: 'BHSrc1FIEysSN534q5yx8V7dRos',
};

export function startGuide(guideId: string) {
  if (window.pendo?.guidesReady) {
    window.pendo.showGuideById(guideId);
  } else {
    window.document.addEventListener('pendo_guides_loaded', () => {
      window.pendo?.showGuideById(guideId);
    });
  }
}

export function triggerGuide(guideId: string) {
  if (['/dashboard/', '/app/boot/'].includes(window.location.pathname)) {
    const escKeyEvent = new KeyboardEvent('keydown', {
      keyCode: 27,
      bubbles: false,
    } as any);

    // Trigger ESC key to close the dropdown
    document.dispatchEvent(escKeyEvent);
    startGuide(guideId);
  } else {
    const urlPrefix = '/dashboard/?pendo=';
    window.location.href = urlPrefix + guideId;
  }
}

export default { triggerGuide, startGuide, guideIds: guides };
