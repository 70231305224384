import React, { Component } from 'react';
import { getIn, FieldProps } from 'formik';

import Field from '../Field';
import { Checkbox, CheckboxProps } from '../../../index';
import FormFieldWrapper, { getLabelId, FormFieldProps } from '../FormFieldWrapper';
import { getAriaInputProps } from '../formAccessibility';

export type FormCheckboxProps = CheckboxProps &
  // helpText refers to the "?" icon besides a field label. FormCheckbox doesn't have a field label.
  Omit<FormFieldProps, 'helpText'> & {
    /**
     * Whether to show validation error when the field is not touched.
     * When the field is touched, error is always showed (unless disableError is set).
     * @default true
     */
    shouldShowErrorIfNotTouched?: boolean;
  };

class FormCheckbox extends Component<FormCheckboxProps> {
  static defaultProps = {
    shouldShowErrorIfNotTouched: true,
  };
  render() {
    const {
      name,
      containerProps,
      format,
      limitRerender,
      dependencies,
      'aria-label': ariaLabel,
      additionalText,
      disableError,
      shouldShowErrorIfNotTouched,
      ...rest
    } = this.props;
    return (
      <Field limitRerender={limitRerender} dependencies={dependencies} name={name}>
        {({ field, form }: FieldProps) => {
          // NOTE: skip `form.touched[name]` to trigger error so that unchecked can be an error
          const error: any = (shouldShowErrorIfNotTouched || getIn(form.touched, name)) && getIn(form.errors, name);
          return (
            <FormFieldWrapper
              fieldType="checkbox"
              name={name}
              error={error}
              containerProps={containerProps}
              format={format}
              additionalText={additionalText}
              disableError={disableError}
            >
              <Checkbox
                id={name}
                className={error ? 'error' : null}
                {...field}
                checked={field.value}
                labelId={getLabelId(name)}
                {...rest}
                {...getAriaInputProps(name, error, ariaLabel)}
                mb={error ? 1 : 0}
              />
            </FormFieldWrapper>
          );
        }}
      </Field>
    );
  }
}

export default FormCheckbox;
