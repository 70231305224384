// A list of legacy switches to exclude which don't confirm to the plg_experiment_* format
const switchToTraitAllowList: { [key: string]: string } = { plg_micro_trials: 'micro_trials' };

// Matches any traits with the format plg_experiment_*
export function buildExperimentValuesFromSwitches(switches: { [key: string]: boolean }) {
  return Object.entries(switches).reduce((traits, [switchName, switchValue]) => {
    const plgExperimentRegex = /plg_experiment_(\S+)/;
    const match = switchName.match(plgExperimentRegex);
    if (match) {
      const experimentName = match[1];
      traits[`experiment_${experimentName}_test_group`] = switchValue;
    } else if (switchToTraitAllowList[switchName]) {
      traits[`experiment_${switchToTraitAllowList[switchName]}_test_group`] = switchValue;
    }
    return traits;
  }, {} as { [key: string]: boolean });
}

export function buildTruthyTraitsFromExperimentSwitches(experimentTraitsFromSwitches: { [key: string]: boolean }) {
  return Object.keys(experimentTraitsFromSwitches).reduce((traits, traitName) => {
    // tslint:disable-next-line
    if (experimentTraitsFromSwitches[traitName] === true) {
      traits[traitName] = true;
    }
    return traits;
  }, {} as { [key: string]: boolean });
}
