export enum SupportFlowPages {
  issueSelect,
  recommendedMethods,
  submitRequest,
  requestReceived,
  openCases,
  welcomeHub,
}

export type SupportFlowPageProps = {
  isMobileView?: boolean;
  goBack?: () => void;
  onClose?: () => void;
  navigateTo: (page: SupportFlowPages, pageOptions?: { [key: string]: any }) => void;
};
