import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { useQueryWithProgress } from 'z-frontend-network';
import { Flex, TextBlock } from 'zbase';
import { Form, FormCustomTileInput } from 'z-frontend-forms';
import { Button } from 'z-frontend-elements';
import { theme } from 'z-frontend-theme';
import { getEventLogger } from 'z-frontend-app-bootstrap';

import { IssueListQuery } from '../gqlTypes';
import { SupportFlowPages, SupportFlowPageProps } from '../support-flow/types';
import { ABTYPE, ALIST } from './constants';

export type Props = SupportFlowPageProps & {
  parentTopicId?: string;
  /**
   * Force the component to use a mobile layout where tiles are full width. If not set will fallback to responsive breakpoints
   */
  isMobileView?: boolean;
};

const goNext = (
  navigateTo: (page: SupportFlowPages, pageOptions?: { [key: string]: any }) => void,
  topics: IssueListQuery.IssueCategories[],
  topicId: string,
  category: string,
  path: string,
  isAdmin: boolean,
  method: string,
) => {
  if (!topicId) {
    return;
  }

  const selectedTopic = topics.filter(topic => {
    if (topic.topicId === topicId) return true;
    return false;
  })[0];

  getEventLogger().log('support_flow_navigation', { path, method: selectedTopic.topicName });

  const { isLeaf } = selectedTopic;

  const isABList = ABTYPE.indexOf(category) >= 0;
  const isABListAdmin = isABList && isAdmin;

  if (isLeaf) {
    if (isABListAdmin) {
      return navigateTo(SupportFlowPages.recommendedMethods, { method, topicId });
    }
    return navigateTo(SupportFlowPages.recommendedMethods, { topicId });
  } else {
    if (isABListAdmin) {
      return navigateTo(SupportFlowPages.issueSelect, { method, parentTopicId: topicId });
    }
    navigateTo(SupportFlowPages.issueSelect, { parentTopicId: topicId });
  }
};

const IssueList: FunctionComponent<{
  topics: IssueListQuery.IssueCategories[];
  category: string;
  isAdmin: boolean;
  path: string;
  method?: string;
} & Props> = props => {
  const { topics, navigateTo, goBack, isAdmin, path, category, method, isMobileView } = props;
  return (
    <>
      <Form onSubmit={values => {}} initialValues={{}}>
        <Flex justify="space-between" wrap>
          {topics.map((card, index) => (
            <FormCustomTileInput
              key={index.toString()}
              name="topicId"
              value={card.topicId}
              containerProps={{
                w: isMobileView ? 1 : [1, null, `calc(50% - ${theme.space[3]}px)`],
                mx: 2,
                mb: 1,
                mt: 1,
              }}
              onClick={() => goNext(navigateTo, topics, card.topicId, category, path, isAdmin, method)}
            >
              <TextBlock
                color="text.dark"
                fontStyle="paragraphs.l"
                px={3}
                py={1}
                style={{
                  height: `52px`,
                  wordWrap: `break-word`,
                }}
              >
                {card.topicName}
              </TextBlock>
            </FormCustomTileInput>
          ))}
        </Flex>
        {goBack && (
          <Flex mt={3} pt={3} borderTop justify="flex-end">
            <Button onClick={() => goBack()}>Back</Button>
          </Flex>
        )}
      </Form>
    </>
  );
};

const IssueSelector: FunctionComponent<Props> = props => {
  const { Loading, QueryError, data } = useQueryWithProgress<IssueListQuery.Query>(issueListQuery, {
    variables: { parentTopicId: props.parentTopicId },
  });

  return (
    Loading ||
    QueryError || (
      <IssueList
        topics={data.issueCategories || []}
        category={data.roleAndCategory?.category || ALIST}
        isAdmin={data.roleAndCategory?.isAdmin || false}
        path={data.decisionTreePath?.path}
        {...props}
      />
    )
  );
};

export const issueListQuery = gql`
  query issueListQuery($parentTopicId: ID) {
    issueCategories(parentTopicId: $parentTopicId) {
      topicName
      topicId
      isLeaf
    }
    decisionTreePath(topicId: $parentTopicId) {
      path
    }
    roleAndCategory {
      category
      isAdmin
    }
  }
`;

export default IssueSelector;
