import React, { useEffect, useState, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import { APP_STYLE_ROOT_CLASS } from 'z-frontend-theme';
import { ErrorBoundary } from 'z-frontend-app-bootstrap';
import { useContractorLiteCompanyFeature } from 'z-frontend-app-bootstrap/src/createIntlProvider';

import SupportFlow from './SupportFlow';
import { SolvvyVersion } from '../SolvvyWindow';
import { SupportFlowPages } from './types';

declare global {
  interface Window {
    startSupportFlow: () => void;
  }
}

const SupportFlowContainer: FunctionComponent<{
  onClose: () => void;
  containerHeight?: number;
  show?: boolean;
  firstStepBack?: () => void;
  initialPage?: keyof typeof SupportFlowPages;
  solvvyVersion: SolvvyVersion;
  isContractorLite?: boolean;
}> = props => {
  useEffect(() => {
    if (document.getElementById('solvvy-v4-launch-widget')) {
      if (props.show) {
        document.getElementById('solvvy-v4-launch-widget').style.display = 'none';
      } else {
        document.getElementById('solvvy-v4-launch-widget').style.display = 'block';
      }
    }

    return () => {
      if (document.getElementById('solvvy-v4-launch-widget')) {
        document.getElementById('solvvy-v4-launch-widget').style.display = 'block';
      }
    };
  }, [props.show]);

  return (
    <SupportFlow
      firstStepBack={props.firstStepBack}
      show={props.show}
      containerHeight={props.containerHeight}
      onClose={props.onClose}
      initialPage={props.initialPage}
      solvvyVersion={props.solvvyVersion}
      isContractorLite={props.isContractorLite}
    />
  );
};

const SupportFlowManager: FunctionComponent<{ initialPage?: keyof typeof SupportFlowPages }> = props => {
  /**
   * To make the handoff with Solvvy seamless we sometimes remove the component from the DOM (no css animation) or leave the DOM node but pass show=false (with css animation)
   * - When we click back to transition back to Solvvy remove without animation
   * - When we click "close" remove Solvvy window sitting behind and dismiss with animation
   */

  const [renderWithProps, setRenderWithProps] = useState<{
    show?: boolean;
    initialPage?: keyof typeof SupportFlowPages;
    solvvyVersion: SolvvyVersion;
  } | null>(null);
  const [solvvyProps, setSolvvyProps] = useState<{ closeSolvvyWindow: () => void }>();
  const [containerHeight, setContainerHeight] = useState(null);
  const isContractorPaymentsCompany = useContractorLiteCompanyFeature();

  useEffect(() => {
    window.startSupportFlow = (options?: { solvvyVerion: SolvvyVersion }) => {
      setRenderWithProps({ show: true, solvvyVersion: options?.solvvyVerion || 'v4' });
    };

    window.document.addEventListener('triggerSupportFlow', (e: CustomEvent) => {
      e.preventDefault();
      setRenderWithProps({ show: true, initialPage: e.detail.page, solvvyVersion: e.detail.solvvyVersion || 'v4' });
      if (e.detail.containerHeight) {
        setContainerHeight(e.detail.containerHeight);
      }
      setSolvvyProps({ closeSolvvyWindow: e.detail.closeSolvvyWindow });
    });

    window.document.addEventListener('closeSupportFlow', () => {
      setRenderWithProps({ ...renderWithProps, show: false });
    });
  }, []);

  return (
    <>
      {props.children}
      {ReactDOM.createPortal(
        /**
         * Normally we would find the existing element with APP_STYLE_ROOT_CLASS and use that as the portal container.
         *
         * Here that doesn't work because the existing element with APP_STYLE_ROOT_CLASS is inside boot app's root element.
         * Boot app's root element has `position: fixed` which creates its own stacking context.
         * We want the content to be on top of the Solvvy element which is outside of boot app's stacking context.
         *
         * Therefore we need to create a new div and put it directly in document body.
         */
        <div className={APP_STYLE_ROOT_CLASS}>
          {renderWithProps && (
            <ErrorBoundary>
              <SupportFlowContainer
                containerHeight={containerHeight}
                solvvyVersion={renderWithProps.solvvyVersion}
                show={renderWithProps.show}
                isContractorLite={isContractorPaymentsCompany}
                initialPage={renderWithProps.initialPage}
                onClose={() => {
                  setRenderWithProps({ show: false, solvvyVersion: renderWithProps.solvvyVersion });
                  solvvyProps.closeSolvvyWindow();
                  window.document.dispatchEvent(new CustomEvent('toggleSupportFlow'));
                }}
                firstStepBack={() => {
                  setRenderWithProps(null);
                  window.document.dispatchEvent(new CustomEvent('toggleSupportFlow'));
                }}
              />
            </ErrorBoundary>
          )}
        </div>,
        document.body,
      )}
    </>
  );
};

export default SupportFlowManager;
