import { getEventLogger } from 'z-frontend-app-bootstrap';

export const openChatWindow = (caseId: string, coordinatorType: string, newWindow = true) => {
  // Height equal to the height of ChatPage
  if (!newWindow) {
    redirectToChatUrl(caseId, coordinatorType);
    return;
  }
  let openedWindow = window.open(
    `/app/support-flow/?is-chat-window=1#/chat/${caseId}/${coordinatorType}`,
    '_blank',
    `width=500,height=613,location=no,left=${window.document.documentElement.clientWidth - 500},top=${window.document
      .documentElement.clientHeight - 613}`,
  );

  // In some cases the browser won't allow a new window to open (ex: mobile). In this case try opening a new tab
  if (!openedWindow) {
    openedWindow = window.open(`/app/support-flow/#/chat/${caseId}/${coordinatorType}`, '_blank');

    if (!openedWindow) {
      redirectToChatUrl(caseId, coordinatorType);
    } else {
      getEventLogger().log('sent_to_chat', {
        destination: 'newTab',
      });
    }
  } else {
    getEventLogger().log('sent_to_chat', {
      destination: 'newWindow',
    });
  }
};

const redirectToChatUrl = (caseId: string, coordinatorType: string) => {
  window.location.href = `/app/support-flow/#/chat/${caseId}/${coordinatorType}`;
  getEventLogger().log('sent_to_chat', {
    destination: 'sameTab',
  });
};
