import React, { Component } from 'react';

import { ResponsiveUtilProp } from 'zbase';

import InputDisplay from '../input/InputDisplay';
import { InputSize } from '../input/inputTypes';
import { MoneyInputOnlyProps } from './MoneyInput';
import { getPrefixSuffixForCurrency } from './getPrefixSuffixForCurrency';
import { formatNumberValue } from '../utils';

export type MoneyInputDisplayProps = MoneyInputOnlyProps & {
  /** Monetary value to display like an input. */
  value: string | number;

  /**
   * Prefix before the formatted value.
   */
  prefix?: string;
  /**
   * suffix after the formatted value.
   */
  suffix?: string;

  s?: InputSize;
  width?: ResponsiveUtilProp;
  /**
   * Align text to right, eg for numeric values in a table.
   */
  textAlignRight?: boolean;
  /**
   * Decimal places to display.
   */
  decimalLimit?: number;
  /**
   * should the value be rounded upto the decimalLimit?
   */
  roundNumber?: boolean;
};

export default class MoneyInputDisplayWrapper extends Component<MoneyInputDisplayProps> {
  static defaultProps = {
    currency: 'USD',
  };

  render() {
    const { prefix, suffix } = getPrefixSuffixForCurrency(this.props.currency, this.props.value);
    return <MoneyInputDisplay prefix={prefix} suffix={suffix} {...this.props} />;
  }
}

class MoneyInputDisplay extends Component<MoneyInputDisplayProps> {
  static defaultProps = {
    s: 'medium',
    allowDecimal: true,
  };

  render() {
    const {
      value,
      s,
      width,
      textAlignRight,
      decimalLimit,
      roundNumber,
      prefix,
      suffix,
      allowDecimal,
      allowNegative,
      integerLimit,
    } = this.props;
    const formattedValue = formatNumberValue(value, {
      prefix,
      suffix,
      allowNegative,
      allowDecimal,
      integerLimit,
      decimalLimit,
      roundNumber,
    });
    return <InputDisplay s={s} width={width} value={formattedValue} textAlignRight={textAlignRight} />;
  }
}
