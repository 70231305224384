import React, { useContext } from 'react';
import gql from 'graphql-tag';

import { throwInDevelopment } from 'z-frontend-app-bootstrap';

import { Query } from '../../index';
import { Permissions } from './permissionTypes';
import { PermissionsRefreshQuery } from '../gqlTypes';
import { AnonymousUserContext } from '../AnonymousUserProvider';

const permissionsRefreshQuery = gql`
  query PermissionsRefreshQuery {
    rolesPermissionsData {
      grants
      spans
    }
  }
`;

export type PermissionsContextProps = {
  permissions: Permissions;
  permissionsLoaded: boolean;
  refetchPermissions: () => any;
};

const nullPermissionsContext = {
  permissions: {},
  permissionsLoaded: true,
  refetchPermissions: () => {
    throwInDevelopment('You tried to fetch permissions outside the context of a permissions manager');
  },
};

export const PermissionsContext = React.createContext<PermissionsContextProps>(nullPermissionsContext);

const PermissionManager: React.FC<{}> = props => {
  const { isAnonymousUser } = useContext(AnonymousUserContext);
  return (
    <Query<PermissionsRefreshQuery.Query, PermissionsRefreshQuery.Variables>
      query={permissionsRefreshQuery}
      // TODO: maybe default of cache-first is fine
      // fetchPolicy="network-only"
      isBackgroundQuery
      handleLoading={false}
      skip={isAnonymousUser}
    >
      {({ data, refetch, loading }) => {
        return (
          <PermissionsContext.Provider
            value={{
              permissions: (data && data.rolesPermissionsData) || {},
              permissionsLoaded: !loading,
              refetchPermissions: refetch,
            }}
          >
            {props.children}
          </PermissionsContext.Provider>
        );
      }}
    </Query>
  );
};

export default PermissionManager;
