import { Analytics } from '@segment/analytics-next';

import { initializeGlobalUtils } from './global-utils';
import segmentSnippet from './segmentSnippet';
import { onProdHost } from '../utils/urlUtils';
import {
  buildExperimentValuesFromSwitches,
  buildTruthyTraitsFromExperimentSwitches,
} from '../utils/getExperimentTraits';

declare global {
  interface Window {
    analytics: Analytics;
  }
}

type Switches = { [switchName: string]: boolean };
export type BootSegmentArguments = {
  switches: Switches;
  userId?: string;
  // Email trait to be set in Segment
  // This email could be used to send marketing emails.
  email?: string | null;
  companyId?: string | null;
  isAnonymous?: boolean;
  isFreeLimitedTrialOrCustomerDemo?: boolean;
  isFreeLimitedTrial?: boolean;
};

type BootCallback = (bootTrails: { [key: string]: any }) => void;
const bootCallbacks: BootCallback[] = [];
let bootTraits: { [key: string]: any } | null = null;

export function addBootCallback(cb: BootCallback) {
  if (bootTraits) {
    return cb(bootTraits);
  }

  bootCallbacks.push(cb);
}

export const visitorTypes = {
  fltc_user_integration_hash: 'fltc_user_integration_hash',
  demo_user_integration_hash: 'demo_user_integration_hash',
  yp_user_integration_hash: 'yp_user_integration_hash',
};

function bootSegment(args: BootSegmentArguments) {
  const { switches } = args;
  // NOTE: we let the boot app bootSegment
  if (window.__WITHIN_EMBER_APP__ && __APP_NAME__ !== 'boot') return;
  if (__DEVELOPMENT__ && !__ENABLE_SEGMENT_LOCALLY__) return;
  if (switches.segment_killswitch) return;
  if (!switches.segment) return;
  const isAnonymousCheckoutVisitor = window.location.pathname.includes('/app/checkout/') && args.isAnonymous;

  if (isAnonymousCheckoutVisitor || args.isFreeLimitedTrialOrCustomerDemo || switches.segment_for_customers) {
    const isOnProd = onProdHost();
    segmentSnippet(isOnProd);
    initializeSegment(args);
    window.analytics.ready(() => {
      initializeGlobalUtils(window.analytics);
    });
  }
}

function initializeSegment(args: BootSegmentArguments) {
  const segment: Analytics = window.analytics;
  const { userId, email, companyId, isFreeLimitedTrialOrCustomerDemo, isAnonymous, switches } = args;

  if (userId || email) {
    const id = isFreeLimitedTrialOrCustomerDemo ? null : userId;

    const experimentTraitsFromSwitches = buildExperimentValuesFromSwitches(switches);
    // Only send when true. If we don't do this we'll override the value with False after the experiment is completed and we turn the switch off
    const truthyTraitsFromSwitches = buildTruthyTraitsFromExperimentSwitches(experimentTraitsFromSwitches);
    const traits: { [Key: string]: any } = {
      email,
      isAnonymous,
      ...truthyTraitsFromSwitches,
    };

    const options = { appName: __APP_NAME__, ...getSegmentExternalIds(args) };

    if (userId) {
      traits[getExternalIdType(args)] = userId;
    }

    segment.identify(id, traits, options);

    bootTraits = traits;
    // Notify any subscribers
    bootCallbacks.forEach(cb => cb(traits));
  }
  if (companyId) {
    // NOTE: this isn't super valuable for Demo users since there is a 1:1 between admin and company
    // but may be more helpful if we ever start using it for paying customer or for Free Limited Trials
    // When that happens, consider adding company traits like plan and addons like we do here
    // https://github.com/zenefits/z-frontend/blob/b5fe7ae2d35673458233ede2b99d04134267afe3/tools/app-bootstrap/src/storeSubscriptionInfo.ts#L77
    segment.group(companyId);
  }
}

function getSegmentExternalIds(args: BootSegmentArguments) {
  const { userId, email } = args;
  const externalIds = [];
  if (email) {
    externalIds.push({
      id: email,
      type: 'email',
      collection: 'users',
      encoding: 'none',
    });
  }
  if (userId) {
    const externalIdType = getExternalIdType(args);
    externalIds.push({
      id: userId,
      type: externalIdType,
      collection: 'users',
      encoding: 'none',
    });
  }

  return externalIds ? { externalIds } : {};
}

function getExternalIdType(args: BootSegmentArguments) {
  if (args.isFreeLimitedTrial) return visitorTypes.fltc_user_integration_hash;
  if (args.isFreeLimitedTrialOrCustomerDemo) return visitorTypes.demo_user_integration_hash;
  return visitorTypes.yp_user_integration_hash;
}

export default bootSegment;
