import React, { createContext, useContext, ReactElement, StatelessComponent } from 'react';
import { injectIntl, FormattedMessage, InjectedIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { merge } from 'lodash';
import IntlMessageFormat from 'intl-messageformat';

import { useFeatures } from './FeaturesProvider';
import baseLocales from './baseLocales';
import { CONTRACTOR_PAYMENTS, IOM } from './constants';

// react-intl will use 'en' as default if a locale for user's browser language is not loaded.
// need to import (or lazy load) corresponding locales when we enable support for other languages.

const localeChangeAction = 'LOCALE_CHANGE';

export const localeChangeActionCreator = (newLocale: string) => ({ type: localeChangeAction, payload: newLocale });

const LocaleRefreshererCore = ({ children, ...rest }: any) => React.cloneElement(children, rest);
export const LocaleRefresherComponent = connect((state: any) => ({
  currentLocale: state.locale.current,
}))(LocaleRefreshererCore as StatelessComponent<{}>);

export const createLocaleReducer = (defaultLocale: string) => (
  state = { current: defaultLocale },
  action: { type: string; payload: any },
) => {
  if (action.type === localeChangeAction) {
    return {
      ...state,
      current: action.payload,
    };
  } else {
    return state;
  }
};

type IntlProviderWrapperParams = {
  children: ReactElement<any>;
  currentLocale: string;
};

export function useContractorLiteCompanyFeature() {
  const includesContractorLiteFeature = useHasFeatures([CONTRACTOR_PAYMENTS], true);
  return includesContractorLiteFeature;
}

export function useContractorWithIOMFeature() {
  const includesContractorWithIOMFeature = useHasFeatures([CONTRACTOR_PAYMENTS, IOM], true);
  return includesContractorWithIOMFeature;
}

export function useIOMFeature() {
  const includesIOMFeature = useHasFeatures([IOM], true);
  return includesIOMFeature;
}

function useGetLocaleSuffix() {
  const isContractorLiteCompany = useContractorLiteCompanyFeature();
  return isContractorLiteCompany ? 'CL' : null;
}

export function useHasFeatures(requiredFeatures: string[], checkAllRequiredFeatures: boolean) {
  const { features, canFetchFeatures, areFeaturesLoaded } = useFeatures();

  if (!areFeaturesLoaded || !canFetchFeatures) {
    return null;
  }

  if (checkAllRequiredFeatures) {
    const includesAllRequiredFeatures = requiredFeatures.every(feature => features?.includes(feature));
    return includesAllRequiredFeatures;
  }
  const includesAnyRequiredFeatures = requiredFeatures.some(feature => features?.includes(feature));
  return includesAnyRequiredFeatures;
}

let localeData: any = baseLocales;
let locale = 'en';

export default function createIntlProvider(appLocaleData?: any, additionalProps?: any) {
  localeData = merge(baseLocales, appLocaleData);

  const IntlProviderWrapper: React.FC<IntlProviderWrapperParams> = ({ children, currentLocale, ...rest }) => {
    locale = currentLocale;

    const languageWithoutRegionCode = currentLocale.toLowerCase().split(/[_-]+/)[0];
    const messagesForLocale = localeData
      ? localeData[languageWithoutRegionCode] || localeData[currentLocale] || localeData.en
      : null;
    const customLocaleSuffix = useGetLocaleSuffix();
    const messagesForLocaleWithCustomSuffix = customLocaleSuffix
      ? localeData[`${languageWithoutRegionCode}__${customLocaleSuffix}`] || {}
      : {};

    const messages = {
      ...messagesForLocale,
      ...messagesForLocaleWithCustomSuffix,
    };

    return (
      <IntlProvider {...rest} key={currentLocale} messages={messages} locale={currentLocale}>
        {children}
      </IntlProvider>
    );
  };

  const ConnectedProvider = connect((state: any) => ({
    currentLocale: state.locale.current,
  }))(IntlProviderWrapper);

  return [
    ConnectedProvider,
    {
      ...(additionalProps || {}),
    },
  ];
}

export const IntlContext = createContext<InjectedIntl>({} as InjectedIntl);

// turn the old context into the new React context
// NOTE: this shouldn't be necessary after upgrading react-intl to v3
export const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

// We are still on react-intl v2 which is pre-hooks. so we have to create our own to wrap the context.
export function useFormatMessage(
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?: { [key: string]: string | number | boolean | Date },
) {
  const { formatMessage } = useContext(IntlContext);
  return formatMessage(messageDescriptor, values);
}

/*
@deprecated

This should ONLY be used to get static messages and not for any messages evaluated asynchronously (ex: anything contractor payments specific).
Since this is not using a hook values will not update automatically after being asynchronously evaluated.

It's preferred to use `useFormatMessage` which will work for async cases, but as we have a bunch of legacy class components this is here for convenience
*/
export function getFormattedMessage(textKey: string, values?: { [key: string]: string | number | boolean | Date }) {
  const message = localeData[locale] && localeData[locale][textKey];
  if (!message) {
    return '';
  }

  return new IntlMessageFormat(message, locale).format(values);
}
