import React from 'react';
import gql from 'graphql-tag';

import { Box, Heading, Icon, Pre, TextBlock, TextInline } from 'zbase';
import { Card } from 'z-frontend-composites';
import { ReadMore } from 'z-frontend-elements';
import { useQueryWithProgress } from 'z-frontend-network';
import { styled, theme } from 'z-frontend-theme';
import { Tooltip } from 'z-frontend-overlays';

import { SupportFlowPageProps } from '../support-flow/types';
import { OpenCasesQuery } from '../gqlTypes';

type Props = SupportFlowPageProps;

type OpenCase = {
  title: string;
  id: string;
  description: string;
};

const DescriptionText = styled(Pre)`
  /* Default font for pre is monospace */
  font-family: ${theme.fonts[0]};
  white-space: pre-wrap;
`;

const OpenCase: React.FunctionComponent<{ openCase: OpenCasesQuery.OpenCases }> = ({ openCase }) => {
  return (
    <Box mt={3}>
      <TextInline mb={1} fontStyle="controls.m">
        Case {openCase.subject} #{openCase.id}
      </TextInline>
      <ReadMore expandedText={<DescriptionText>{openCase.description.trim()}</DescriptionText>}>
        {openCase.description}
      </ReadMore>
    </Box>
  );
};

const OpenCases: React.FunctionComponent<Props> = () => {
  const { Loading, QueryError, data } = useQueryWithProgress<OpenCasesQuery.Query>(openCasesQuery, {
    showInlineLoading: true,
  });
  return (
    Loading ||
    QueryError || (
      <Card>
        <Card.Row>
          <Heading textAlign="center" level={4} fontStyle="controls.l">
            My open cases
            <Tooltip placement="top" showArrow targetBody={<Icon mx={2} iconName="help-outline" />}>
              <TextBlock p={2}>Only cases that were created by you, through chat or email, will appear here.</TextBlock>
            </Tooltip>
          </Heading>
          {data.openCases && data.openCases.length ? (
            data.openCases.map(openCase => <OpenCase key={openCase.id} openCase={openCase} />)
          ) : (
            <TextBlock textAlign="center" mt={3} fontStyle="paragraphs.s">
              You currently don't have any open cases.
            </TextBlock>
          )}
        </Card.Row>
      </Card>
    )
  );
};

export const openCasesQuery = gql`
  query OpenCasesQuery {
    openCases {
      id
      subject
      description
    }
  }
`;

export default OpenCases;
