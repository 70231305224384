import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { NetworkStatus } from 'apollo-client';

import { PrerequisiteQuery } from '../gqlTypes';
import { getRedirectUrl } from './prerequisiteRedirectUtil';

export const prerequisiteQuery = gql`
  query PrerequisiteQuery {
    prerequisiteRedirect
  }
`;

export const PrerequisiteContext = React.createContext<{
  prerequisiteData: PrerequisiteQuery.Query['prerequisiteRedirect'];
  refreshPrerequisites: () => void;
} | null>(null);

export default function useQueryPrerequisites() {
  const { loading, data, refetch, networkStatus } = useQuery<PrerequisiteQuery.Query>(prerequisiteQuery, {
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (!loading && networkStatus !== NetworkStatus.refetch) {
      const redirectUrl = getRedirectUrl(
        data?.prerequisiteRedirect || [],
        window.location.pathname,
        window.location.hash.slice(1),
        window.location.search,
      );

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }, [data, loading, networkStatus]);

  const history = useHistory();
  // Need to wrap this in useEffect otherwise we register a new listener everytime
  useEffect(() => {
    // We're abusing block a little bit. Usually we return a string to show an alert before transition completes, in this case we never return a string for prompt but optionally redirect.
    const unblock = history.block((location: Location) => {
      if (!loading && location.pathname !== '/') {
        const redirectUrl = getRedirectUrl(
          data?.prerequisiteRedirect || [],
          window.location.pathname,
          location.pathname,
          location.search,
        );
        if (redirectUrl) {
          // In this case block existing transition and then perform redirect on next tick. Redirecting within the block can put us in a weird state with multiple concurrent active transitions.
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 0);

          // Returning false will block the transition
          return false;
        }
      }
    });
    return () => {
      unblock();
    };
  }, [loading]);

  return {
    loading,
    data,
    refetch,
  };
}
