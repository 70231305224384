import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { styled, theme, APP_STYLE_ROOT_CLASS, Hide, Render } from 'z-frontend-theme';
import { CardContainer, CardHeader, IconButton } from 'z-frontend-elements';
import { space } from 'z-frontend-theme/utils';
import { Flex, Heading } from 'zbase';

import BasePopper, { BasePopperProps, PlacementProp } from './BasePopper';
import ArrowedPopperContainer from './ArrowedPopperContainer';
import PopoverModal from './PopoverModal';

type PopoverOwnProps = {
  /**
   * @deprecated Please use Tooltip if you want content to show on hover
   *
   * Show popover content when the user clicks or hovers on the target element.
   * Note: Popover on hover is not encouraged.
   * @default click
   */
  eventDeprecated?: 'click' | 'hover';
  /**
   * Wrap the popover in a StyledPopperContainer component which provides a container with a border and depth for the content to live in
   * @default true
   */
  useDefaultPopperContainer?: boolean;
  title?: string;
  /**
   * Should the popover take up the full screen on mobile devices?
   * @default true
   * */
  showFullScreenMobile?: boolean;
  /**
   * By default the component will look for an element by APP_STYLE_ROOT_CLASS, e.g. boot-app-style-root.
   * If it exists, it will be used as a container to create a portal to render the popper.
   * This is to avoid any existing z-index stacking context in the component tree.
   *
   * However, in some cases we actually want the popper to be rendered in the original place to inherit the z-index
   * stacking context so that it can render properly. In that case we can set shouldNotUsePortal to true.
   * @default false
   */
  shouldNotUsePortal?: boolean;
};
export type PopoverProps = Omit<BasePopperProps, 'children' | 'event'> & PopoverOwnProps;

const Body = styled(CardContainer)`
  margin-bottom: 0;
  padding: 0 ${space(5)} ${space(4)} ${space(3)};
`;

const Header = styled(CardHeader)`
  border: none;
  padding: ${space(2)} ${space(3)};
`;

const DefaultModalContainer = styled(Flex)`
  padding: ${space(2)} ${space(3)};
`;

class Popover extends Component<PopoverProps> {
  static defaultProps = {
    useDefaultPopperContainer: true,
    showFullScreenMobile: true,
    eventDeprecated: 'click',
  };

  static Header = Header;

  static Body = Body;

  renderContent = (closePopper: () => void) => {
    if (this.props.title) {
      return (
        <>
          <Popover.Header>
            <Flex justify="space-between" align="center">
              <Heading level={5} fontStyle="controls.m">
                {this.props.title}
              </Heading>
              <IconButton iconName="close" s="small" color="grayscale.d" onClick={closePopper} mr={-2} />
            </Flex>
          </Popover.Header>
          {this.props.children}
        </>
      );
    } else if (!this.props.showFullScreenMobile) {
      return this.props.children;
    }

    return (
      <>
        <Render forBreakpoints={[true]}>
          <DefaultModalContainer direction="row" justify="space-between" align="baseline">
            {this.props.children}
            <IconButton iconName="close" s="small" color="grayscale.d" onClick={closePopper} />
          </DefaultModalContainer>
        </Render>
        <Hide forBreakpoints={[true]}>{this.props.children}</Hide>
      </>
    );
  };

  render() {
    const {
      showArrow,
      useDefaultPopperContainer,
      showFullScreenMobile,
      title,
      eventDeprecated,
      shouldNotUsePortal,
      popperModifiers: customPopperModifiers,
    } = this.props;

    const popperModifiers: BasePopperProps['popperModifiers'] = {
      preventOverflow: {
        boundariesElement: 'viewport',
      },
      ...customPopperModifiers,
    };
    const portalContainer = document.getElementsByClassName(APP_STYLE_ROOT_CLASS)[0];

    return (
      <BasePopper event={eventDeprecated} {...this.props} popperModifiers={popperModifiers}>
        {({ placement, style, ref, arrowProps }, isVisible, closePopper) => {
          const popperContent = (
            <div style={{ ...style, zIndex: theme.zIndex.popover }} ref={ref}>
              <ArrowedPopperContainer
                arrowProps={arrowProps}
                dataPlacement={placement as PlacementProp}
                showArrow={showArrow}
                useDefaultPopperContainer={useDefaultPopperContainer}
                bg="grayscale.white"
                color="text.default"
              >
                {this.renderContent(closePopper)}
              </ArrowedPopperContainer>
            </div>
          );

          return (
            <>
              <Hide forBreakpoints={showFullScreenMobile ? [true] : []}>
                {portalContainer && !shouldNotUsePortal
                  ? ReactDOM.createPortal(popperContent, portalContainer)
                  : popperContent}
              </Hide>

              <Render forBreakpoints={showFullScreenMobile ? [true] : []}>
                <PopoverModal title={title} onCancel={closePopper} isVisible={isVisible}>
                  {this.renderContent(closePopper)}
                </PopoverModal>
              </Render>
            </>
          );
        }}
      </BasePopper>
    );
  }
}

export default Popover;
