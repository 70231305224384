import React from 'react';

import { Box, TextBlock } from 'zbase';
import { styled } from 'z-frontend-theme';
import { space } from 'z-frontend-theme/utils';

/**
 * Used for display mode to align additional text with input value
 */
export const AdditionalTextContainer = styled(Box)`
  /* mimic the border of input */
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding-left: ${space(3)};
  padding-right: ${space(3)};
`;

export default ({ text }: { text?: string | JSX.Element }) =>
  !text ? null : (
    <TextBlock mb={2} color="text.off">
      {text}
    </TextBlock>
  );
