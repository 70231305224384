import React, { useEffect, useState, Component } from 'react';
import {
  useFormikContext,
  FieldArray as FormikFieldArray,
  Form as FormikForm,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from 'formik';
import * as Yup from 'yup';

import { getEventLogger } from 'z-frontend-app-bootstrap';

import FormError from './error/FormError';
import FormSection from './section/FormSection';
import FormFieldWrapper from './FormFieldWrapper';
import FormFooter from './footer/FormFooter';
import FormDebug from './FormDebug';
import FormRowGroup from './row-group/FormRowGroup';
import FormEffect, { FormOnChange } from './effect/Effect';
import FormContext from './FormContext';
import getErrorMessage from './utils/getErrorMessage';
import PreventTransition from './preventTransition/PreventTransition';

export interface FormProps<TValues> {
  /** Initial values of the form, eg when editing an employee. */
  initialValues: TValues;
  /**
   * Initial field errors of the form.
   */
  initialErrors?: FormikErrors<TValues> & { [key: string]: string };
  /** Action to take when submitting the form. If you return a promise, the submit status of the form is handled for you.  */
  onSubmit: (values: TValues, actions: FormikHelpers<TValues>) => Promise<any> | void;
  /** Contents of the form, such as inputs. Optionally a function that takes render props. */
  children: ((props: FormikProps<TValues>) => React.ReactNode[]) | React.ReactNode;
  /** Each value can optionally have a schema that includes error messages. */
  validationSchema?: { [V in keyof TValues]?: Yup.MixedSchema }; // TODO: this does not error on extras not in TValues
  validate?: (values: TValues) => FormikErrors<TValues> | Promise<any>;
  /**
   * Control whether Formik should reset the form if the wrapped component props change (using deep equality).
   * @default false
   */
  enableReinitialize?: boolean;
  /**
   * Enabling this will prevent transitions when the form is dirty
   * @default false
   */
  preventTransition?: boolean;
  /**
   * Custom message which gets rendered on the <Prompt />
   */
  preventTransitionMessage?: string;
  /**
   * Display internal formik state for debugging purposes. Not to be used in production!
   * @default false
   */
  debug?: boolean;
  /**
   * Trigger a side effect based on a state change. Please do not attempt to "sync" form state in elsewhere.
   */
  onChange?: FormOnChange<TValues>;
  /**
   * Optimizes all form field so that they only re-render when their value changes. Changes to values not in this slice of formik state,
   * will not trigger a re-render. This also means updating other props like `containerProps` will not trigger a re-render.
   * Under the covers setting this flag will use a Formik `<FastField>` component. Please read the FastField docs: https://jaredpalmer.com/formik/docs/api/fastfield before using.
   * This flag can be overridden at the field level.
   */
  limitRerender?: boolean;
}

function setEquals(setA: Set<string>, setB: Set<string>) {
  if (setA.size !== setB.size) {
    return false;
  }
  for (const el of setA) {
    if (!setB.has(el)) {
      return false;
    }
  }
  return true;
}

// Exported for testing
export function evaluateNewTouchedErrors(
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  prevTouchedErrors: Set<string>,
) {
  const newTouchedErrors = new Set(Object.keys(errors).filter(fieldKey => !!touched[fieldKey]));

  // See if touched errors have changed
  if (setEquals(newTouchedErrors, prevTouchedErrors)) {
    return null;
  }

  return { errors, touchedErrors: newTouchedErrors };
}

const FormikValidate: React.FC<{}> = ({ children }) => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [touchedErrors, setTouchedErrors] = useState<Set<string>>(new Set<string>());
  const { values, validateForm, errors, touched, submitCount, isSubmitting } = useFormikContext();
  useEffect(() => {
    // Only run validation on updates not in initial mount
    if (!isInitialRender) {
      validateForm();
    }
    setIsInitialRender(false);
  }, [values]);

  // Log form errors when fields are touched or get new errors
  useEffect(() => {
    const newErrorsAndTouchedFields = evaluateNewTouchedErrors(errors, touched, touchedErrors);
    if (newErrorsAndTouchedFields) {
      if (Object.keys(newErrorsAndTouchedFields.errors).length) {
        // eslint-disable-next-line no-restricted-properties
        getEventLogger().batchLogWithoutSchemaValidation('form_errors', {
          formErrors: newErrorsAndTouchedFields.errors,
        });
      }
      setTouchedErrors(newErrorsAndTouchedFields.touchedErrors);
    }
  }, [errors, touched]);

  // Log submit attempts when there are validation errors
  useEffect(() => {
    if (submitCount > 0 && !isSubmitting && errors && Object.keys(errors).length) {
      // eslint-disable-next-line no-restricted-properties
      getEventLogger().batchLogWithoutSchemaValidation('form_submit_validation_error', {
        formErrors: errors,
      });
    }
  }, [isSubmitting]);

  return <>{children}</>;
};

class Form<TValues> extends Component<FormProps<TValues>> {
  // NOTE: this list must be kept in sync with formik/index.ts for docs
  // Form layout components
  static Footer = FormFooter;
  static Error = FormError;
  static Section = FormSection;
  static RowGroup = FormRowGroup;
  static Row = FormFieldWrapper;

  // Other library exports
  static Yup = Yup;

  render() {
    const {
      initialValues,
      initialErrors,
      onSubmit,
      validationSchema,
      validate,
      children,
      enableReinitialize,
      debug,
      onChange,
      preventTransition,
      preventTransitionMessage,
      limitRerender,
      ...rest
    } = this.props;
    const schema = validationSchema ? Yup.object().shape(validationSchema) : undefined;

    function handleSubmit(values: TValues, actions: FormikHelpers<TValues>) {
      const result: any = onSubmit(values, actions);
      if (result instanceof Promise) {
        const endSubmit = () => {
          getEventLogger().batchLog('form_submitted', {});
          actions.setSubmitting(false);
        };
        result.then(endSubmit).catch(errorObj => {
          // Formik provides setFieldError() to implicitly set the error
          // which we can consume in the renderProps as errors['onSubmitError']
          // (FormFooter does this by default)
          const error = getErrorMessage(errorObj);
          actions.setFieldError('onSubmitError', error);
          getEventLogger().batchLog('form_submit_error', {
            error: errorObj,
          });
          endSubmit();
        }); // Promise.finally is not universally supported yet
      } else {
        getEventLogger().batchLog('form_submitted', {});
      }
    }

    return (
      <FormContext.Provider value={{ limitRerender }}>
        <Formik
          initialValues={initialValues}
          initialErrors={initialErrors}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validate={validate}
          enableReinitialize={enableReinitialize}
        >
          {(props: FormikProps<TValues>) => {
            return (
              // Temporary workaround to this bug https://github.com/jaredpalmer/formik/issues/2120
              // @ts-ignore
              <FormikForm
                noValidate // disable browser validation (which is not accessible nor theme-able)
                {...rest}
              >
                {onChange && <FormEffect onChange={onChange} />}
                {preventTransition && (
                  <PreventTransition dirty={props.dirty} preventTransitionMessage={preventTransitionMessage} />
                )}
                {debug && <FormDebug />}
                <FormikValidate>
                  {typeof children === 'function'
                    ? (children as (props: FormikProps<TValues>) => React.ReactNode[])(props)
                    : children}
                </FormikValidate>
              </FormikForm>
            );
          }}
        </Formik>
      </FormContext.Provider>
    );
  }
}

export { TimeInputValue } from './time-input/FormTimeInput';
export { TimeRangeValue } from './time-range/FormTimeRange';
export { DateRangeValue } from './date-range/FormDateRange';
export { MentionOption } from '../mention/MentionSelect';
export { AutoTextOption } from '../template-editor/types';
export { AddressValue } from './address/addressUtils';
export { SignatureValue } from '../signature/Signature';
export { FormCheckboxProps } from './checkbox/FormCheckbox';
export { FormFieldFormat } from './FormFieldWrapper';
export { Form };
export { FormikFieldArray };
