import { HTMLAttributes } from 'react';

import withWebUtilProps, { ResultWebComponentProps } from './withUtilPropsWeb';

type PreAttrs = HTMLAttributes<HTMLPreElement>;

export type TextareaProps = ResultWebComponentProps<PreAttrs>;

export default withWebUtilProps<PreAttrs>({
  displayName: 'Pre',
  defaultUtilProps: {
    bg: 'transparent',
    fontStyle: 'paragraphs.m',
  },
  utilTypes: ['padding', 'font', 'color', 'bg'], // omit margin and width, which do not apply to inline elements
})('pre');
