import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// @ts-ignore
import { conformToMask } from 'react-text-mask';

export function countDecimals(value: number | string) {
  if (!value && value !== 0) return 0;

  let valueAsNumber = null;
  if (typeof value === 'string') {
    const valueWithoutCommas = value.replace(/,/g, ''); // We get NaN if we pass a string with commas to Number
    valueAsNumber = Number(valueWithoutCommas);
  } else {
    valueAsNumber = value;
  }
  if (Math.floor(valueAsNumber) === valueAsNumber) return 0;
  const valueAsString = String(value);
  return valueAsString.split('.')[1]?.length || 0;
}

type NumberFormatConfig = {
  prefix?: string;
  suffix?: string;
  allowNegative?: boolean;
  allowDecimal?: boolean;
  integerLimit?: number;
  decimalLimit?: number;
  includeThousandsSeparator?: boolean;
  roundNumber?: boolean;
};

export function formatNumberValue(value: number | string, config: NumberFormatConfig) {
  const valueUnspecified = value === undefined || value === null || value === '';
  if (valueUnspecified) return value;

  const { decimalLimit, roundNumber, ...otherConfigs } = config;
  const numberMask = createNumberMask({ decimalLimit, ...otherConfigs });

  const decimals = countDecimals(value);
  if (decimalLimit && roundNumber && decimals > decimalLimit) {
    // round number only if number of decimals exceed decimalLimit
    const floatValue = typeof value === 'string' ? parseFloat(value) : value;
    const roundedNumber = floatValue.toFixed(decimalLimit);
    return conformToMask(String(roundedNumber), numberMask).conformedValue;
  }
  return conformToMask(String(value), numberMask).conformedValue;
}
